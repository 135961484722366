.greenAlertContainer {
	background-color: #05CE78;
	color: #FFFFFF;
	height: 80px;
	line-height: 92px;
	display: flex;
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999 !important;
}

.greenAlertContainer .greenAlertText {
	display: flex;
	justify-content: space-between;
}

.greenAlertContainer .checkText {
	display: inline-block;
	position: relative;
    bottom: 6px;
    left: 10px;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;

}

@media (min-width: 1280px) {
    .greenAlertContainer .MuiContainer-maxWidthLg {
        max-width: 1140px;
    }
}

.greenAlertContainer .crossImg {
	cursor: pointer;
}