.stepper3-component .carousel .slide {
	background: none !important;
}

.stepper3-component {
	overflow: hidden;
}

.stepper3-component .stepertitle1 {
	text-align: left;
}

.stepper3-component .system-organ-label {
	color: #4D4D4D;
	font-family: 'Poppins', sans-serif;
	font-size: 14px;
	text-align: left;
}

.stepper3-component .system-organ-label .counter {
	width: 32px;
    height: 32px;
    display: inline-block;
    text-align: center;
    line-height: 32px;
    background-color: #2A82F6;
    color: #fff;
    border-radius: 32px;
    margin-right: 10px;
    margin-bottom: 15px;
}

.stepper3-component .radio {
	text-align: left;
	border: 1px solid #E4E8EC;
	padding: 20px 22px;
    border-radius: 5px;
    margin-bottom: 15px;
}

.stepper3-component .radio input {
	margin-right: 10px;
}

.stepper3-component .radio label {
	color: #4D4D4D;
	font-size: 14px;
	font-family: 'Poppins', sans-serif;
	display: flex;
}

.stepper3-component .carousel .carousel-status {
	display: block !important;
}

.stepper3-component .carousel .control-dots {
	margin: -20px 0;
}

.finished-screen {
	margin-top: 91px !important;
}

.carousel .carousel-status {
    position: absolute;
    top: -67px !important;
    right: 0 !important;
    padding: 5px !important;
    text-shadow: none !important;
    color: #D3D8DA !important;
    font-size: 14px !important;
    font-weight: 500 !important;
}

.radio label input{
	width: 20px;
	height: 20px;
}

.stepper3-component .control-arrow {
	display: none;
}

.query-slider-controller {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.query-slider-controller .slide-counter {
	font-size: 14px;
	color: rgb(185, 185, 185);
	text-align: right;
}

.query-slider-controller .slide-counter svg {
	width: 15px;
    position: relative;
	top: 7px;
	color: #6b6a6a;
	padding: 0 5px;
}