.about-tab-section {
	background-color: #F7F8FA;
}

.about-tab-section header.MuiPaper-root	{
	display: block !important;
}

.about-tab-section .MuiAppBar-colorPrimary {
	background-color: #F7F8FA !important;
}

.about-tab-section .MuiTabs-fixed .MuiTab-root {
	color: #4D4D4D;
}

.about-tab-section .MuiTabs-fixed .MuiTab-root.Mui-selected {
	color: #7266FB;
}


.about-tab-section .MuiTabs-flexContainer {
	justify-content: center;
}

.tab-content {
	margin-top: 80px;
}

.tab-content .tab-title {
    color: #7266FB;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    display: flex;
    justify-content: center;
}

.hearticon {
    color: #7266FB;
    font-size: 20px !important;
    margin: 0 3px;
    position: relative;
    top: 4px;
}
.tab-content .tab-plain-title {
	text-align: center;
	color: #4D4D4D;
	font-size: 30px;
	font-family: 'Poppins', sans-serif;
	line-height: 1.5;
	font-weight: bold;
	max-width: 70%;
	margin: 15px auto;
}

.tab-content .tab-plain-text {
    text-align: left;
    color: #818E94;
    font-size: 18px !important;
    font-family: 'Poppins', sans-serif !important;
    margin: 40px auto !important;
    line-height: 34px;
}

.tab-content .continue-reading {
    color: #7266FB;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 600;
    margin-left: 0 !important;
    padding: 8px;
}
.tab-content .tab-information {
	margin: 0 auto;
	max-width: 80%;
}



@media (max-width: 576px) {
	.tab-content .tab-plain-text, .work-plain-text {
		font-size: 14px !important;
	}
}