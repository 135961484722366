.homeBanner {
	padding: 70px 0 80px 0;
	font-family: 'Poppins', sans-serif;
}

.homeBanner .text-banner {
	word-break: break-word !important;
}

.homeBanner .text-banner .black-text {
	color: #020814;
	font-weight: 700;
	font-family: inherit;
	font-size: 58px;
	font-family: 'Poppins', sans-serif;
}


.homeBanner .text-banner a {
	text-decoration: none;
}

.homeBanner .carousel, .homeBanner .carousel .control-dots, .homeBanner .carousel .carousel-status {
	display: none;
}

.homeBanner .carousel li.slide {
	text-align: left;
	/* margin-top: 15px; */
}

.homeBanner .carousel li.slide p {
	font-size: 52px;
    font-weight: 700;
    line-height: 1;
    padding: 10px 0;
}

@media (min-width:890px) and (max-width:1080px){
	.homeBanner .carousel li.slide p {
		font-size: 50px;
	}
}

@media (max-width: 420px){
	.homeBanner .carousel li.slide p {
		font-size: 20px;
	}
}


.homeBanner .m-event {
	color: #7266FB;
}

.homeBanner .m-salah {
	color: #3263FF;
}

.homeBanner .d-donation {
	color: #05CE78;
}

.homeBanner .fundraise {
	color: #EB5310;
}

.homeBanner .announce {
	color: #3263FF;
}

.homeBanner .kiosks {
	color: #F7A130;
}

.homeBanner .website {
	color: #5DFF9B;
}

.homeBanner .carousel .slide {
	background-color: transparent;
}

.homeBanner .carousel.carousel-slider {
	display: block;
	overflow: visible !important;
}

.carousel.carousel-slider {
    position: relative;
    margin: 0;
    overflow: visible !important;
}

.homeBanner .text-banner .blue-text {
	color: #7266FB;
	font-weight: 600;
	font-family: inherit;
	font-size: 58px;
}

.banner-plain {
	padding-right: 150px;
    font-size: 18px !important;
    color: #818E94;
    line-height: 1.8 !important;
    font-family: 'Poppins', sans-serif !important;
    margin: 30px 0 !important;
}

.free-start-btn {
	background-color: #7266FB !important;
}

.blueBanner {
	background-color: #F7F8FA;	
	padding: 80px 0;
}

.blueBanner img {
	float: right;
}

.blueBanner a {
	text-decoration: none !important;
}

.blueBanner  .blue_banner{
	width: 772px;
}

.blueBanner .b-text {
	color: #7266FB !important;
	font-size: 18px !important;
	font-family: 'Poppins', sans-serif !important;
	font-weight: 700 !important;
	text-transform: uppercase;
}

.blueBanner .one-text {
	color: #2D2D2D !important;
	font-size: 30px !important;
	font-family: 'Poppins', sans-serif !important;
	font-weight: bold;
	padding-right: 130px;
	margin-top: 10px;
}

.blueBanner .organ-text {
	color: #818E94 !important;
	font-size: 18px !important;
	font-family: 'Poppins', sans-serif !important;
	line-height: 1.8;
	padding-top: 21px;
}

.blueBanner .get-text-btn {
    color: #7266FB;
    font-size: 14px;
    margin: 30px 0 0 0;
    font-weight: 700;
    padding: 0;
    font-family: 'Poppins', sans-serif !important;
}

.blueBanner .get-text-btn:hover {
    background:none;
}
.connect-section {
	background-color: #7266FB;
	padding: 80px 0;
}

.connect-section .connect-section-content {
	background-color: #fff;
	text-align: center;
	padding: 50px 60px;
	border-radius: 4px;
}

.connect-blue {
	color: #7266FB !important;
	font-family: 'Poppins', sans-serif !important;
	font-weight: bold !important;
	font-size: 18px !important;
	margin-bottom: 10px !important;
	text-transform: uppercase;
}

.connect-title {
	color: #4D4D4D !important;
	font-size: 30px !important;
	font-family: 'Poppins', sans-serif !important;
	font-weight: bold !important;
	margin-bottom: 20px !important;
}

.connect-section .connect-text {
	color: #818E94;
	font-size: 18px;
	font-family: 'Poppins', sans-serif;
	line-height: 34px;
}

.connect-input-group {
	margin-top: 30px;
}

.connect-input-group input {
    width: 100%;
    border: 1px solid #E4E8EC;
    padding: 0px 24px;
    border-radius: 100px;
    font-family: Roboto !important;
    font-size: 16px;
    height: 56px;
    color: #4D4D4D;
}
.connect-input-group button.free-start-btn {
    position: absolute;
    margin-left: 0;
    right: -44px;
    top: 6px;
	height: 46px;
	max-width: initial !important;
}

.connect-input-group button.disabled-email-btn {
	background-color: rgba(0, 0, 0, 0.12) !important;
}

.empower-section {
	padding: 80px 0;
	text-align: center;
}

.empower-section .empower-plain {
	color: #818E94;
	font-size: 18px;
	font-family: 'Poppins', sans-serif;
	line-height: 1.8;
	max-width: 60%;
    margin: 0 auto;
}



.empower-section .empower-card {
	text-align: left;
	border: #E4E8EC 1px solid;
	padding: 30px 20px;
}

.empower-section .MuiButton-text {
    padding:0;
}

.empower-card .MuiCardContent-root {
    padding: 0 !important;
}

.empower-section .empower-card:hover {
	box-shadow: 0px 1px 1px -1px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 10px 0px rgba(0,0,0,0.12) !important;
}

.learn-text-btn {
	color: #4D4D4D !important;
	font-weight: 700 !important;
	font-family: 'Poppins', sans-serif !important;
}

.empower-section .empower-card:hover .learn-text-btn {
	color: #7266FB !important;
}


.MuiButton-label .fa-angle-right {
    font-size: 19px;
    position: relative;
    top: -1px;
	left: -1px;
	font-weight: 700;
}



.empower-section .empower-card .empower-list img {
    position: relative;
    top: 5px;
    margin-right: 5px;
}

.empower-section .deen-web-t {
	color: #4D4D4D;
	font-size: 26px;
	font-family: 'Poppins', sans-serif;
	position: relative;
    bottom: 10px;
    left: 6px;
}

.empower-section .empower-list .MuiTypography-colorTextSecondary {
	font-family: 'Poppins', sans-serif !important;
	margin-bottom: 16px;
}

.empower-section .learn-text-btn.gray {
	color: #4d4d4d !important;
}

.empower-section hr {
	margin: 20px 0 15px;
}

.empower-section .free-start-btn.more-app {
	margin: 40px 0 25px;
	font-family: 'Poppins', sans-serif;
}

.about-home-section {
	background-color: #F7F8FA;
	padding: 80px 0;
}

.about-home-section .about-plain-text {
	color: #818E94;
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
	line-height: 1.8;
	max-width: 553px;
}

.about-home-section .about-plain-text a{
	color: #7266FB;
	font-size: 14px;
	letter-spacing: 1.26px;
	margin-top: 27px;
	display: inline-block;
	text-transform: uppercase;

}

.about-home-section .connect-title {
	font-size: 30px !important;
}

.home_about_img{
	width: 706px;
	margin-top: -34px;
}

.aboutUsContainer{
	position: relative;
}

.aboutusImg{
	width: 500px !important;
    margin-top: -34px;
    position: absolute;
    right: -50px;
    bottom: -80px;
}


.join-momentum {
	text-align: center;
	padding: 80px 0;
}

.join-momentum .connect-title {
	max-width: 75%;
    margin: 0 auto;
}

.join-momentum .carousel .slide {
	background-color: transparent;
}

.join-momentum .carousel-images img {
    width: 230px;
    padding: 15px;
	cursor: pointer;
	-webkit-filter: grayscale(100%); /* Ch 23+, Saf 6.0+, BB 10.0+ */
  	filter: grayscale(100%); /* FF 35+ */
	
}


.join-momentum .carousel-images img:hover{
	-webkit-filter: grayscale(0%) !important; /* Ch 23+, Saf 6.0+, BB 10.0+ */
  	filter: grayscale(0%) !important; /* FF 35+ */
}


.join-momentum .carousel.carousel-slider {
	display: block !important;
}

.join-momentum .carousel-root .carousel {
	display: none;
}

.join-momentum .carousel .control-dots {
	position: unset !important;
}
.carousel .control-dots .dot{
	box-shadow: none !important;
	background: #D3D8DA !important;
	width: 10px !important;
	height: 10px !important;
}

.join-momentum .carousel .control-dots .dot.selected {
	background: #7266FB !important;
	box-shadow: none;
}

.join-momentu .carousel-root {
	margin-top: 40px;
}

.made-with-section {
	background-color: #F7F8FA;
	padding: 80px 0;
	text-align: center;
}

.made-with-section .sliderText {
	color: #2D2D2D;
	font-family: 'Poppins', sans-serif !important;
	font-size: 20px !important;
	line-height: 36px;
}

.made-with-section .sliderlabel {
	color: #4D4D4D;
	font-family: 'Poppins', sans-serif !important;
	font-size: 16px !important;
	padding-top: 25px;
}

.made-with-section .mini-label {
	color: #818E94;
	font-family: 'Poppins', sans-serif !important;
	font-size: 12px !important;
}

.carousel-height .carousel .slider-wrapper {
	height: 78px !important
}

.made-with-section .carousel-images {
	display: flex;
	background: #fff;
    padding: 35px;
}

.made-with-section .carousel-images img {
	width: auto !important;
}

.made-with-section .made-slider-text-section {
	text-align: left;
	padding-top: 20px;
	padding-left: 10px;
}

.made-with-section .carousel .thumb img {
	width: 24px;
    height: 24px;
    border-radius: 40px;
    position: absolute;
    left: 2px;
	bottom: 3px;
	

}

.made-with-section .carousel .thumb {
    min-width: 195px !important;
    background-color: #fff;
    border-radius: 45px;
    position: relative;
    height: 32px;
    display: flex;
	align-items: center;
	box-shadow: 0px 1px 1px -1px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 10px 0px rgba(0,0,0,0.12) !important;
	cursor: pointer;
}

.made-with-section .carousel .thumb:nth-child(3) {
    min-width: 145px !important;
} 

.made-with-section .carousel .thumb:after {
    position: relative;
    top: 0;
    left: 34px;
    font-family: 'Poppins', sans-serif !important;
    font-size: 14px;
}
.made-with-section .carousel .thumb:first-child:after {
	content: "Shayan Mahmud";
}

.made-with-section .carousel .thumb:nth-child(2):after {
	content: "Abullah Al Razi";
} 

.made-with-section .carousel .thumb:nth-child(3):after {
	content: "Hossain Miraz";
} 

.made-with-section .carousel .thumb:nth-child(4):after {
	content: "Farhan Bin Abdullah";
} 

.made-with-section .carousel .thumb:nth-child(5):after {
	content: "Nafseen Al Shoaib";
} 

.carousel .thumb.selected, .carousel .thumb{
	width: auto !important;
	padding: 0 !important;
}

.carousel .thumb, .carousel .thumb{
	border: 1px solid #E8E9E9 !important;
	width: 64px;
	height: 64px;
	border-radius: 4px;
	cursor: pointer;
}

.carousel .thumb.selected, .carousel .thumb:hover{
	border: 1px solid #7266FB !important;
	background-color:#7266FB;
	color: #fff;
}

.made-with-section .carousel .carousel-images .personImg {
	position: relative;
}

.made-with-section .carousel-images .play-circle {
	position: absolute;
	top: 40%;
	left: 40%;
}

.made-with-section .carousel-images .personImg-bg {
	background-color: #7266FB;
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0.6;
}

.made-with-section .carousel-images .play-circle-img {
	position: absolute;
	z-index: 999;
}

.made-with-section .carousel-images .content-carousel {
	background-color: transparent;
	display: flex;
}

.made-with-section .carousel .carousel-status {
	display: none;
}

.made-with-section .carousel .control-dots {
	display: none;
}

.made-with-section .carousel-slider .arrowStyles.prev {
    left: -100px !important;
    top: 45% !important;
}

.made-with-section .carousel-slider .arrowStyles.fwd {
	right: -100px !important;
    top: 45% !important;
}

.made-with-section .carousel-slider .arrowStyles {
	font-size: 22px !important;
    position: absolute !important;
    background: #fff !important;
    width: 48px !important;
    height: 48px !important;
	box-shadow: 0px 1px 0px -2px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 4px 0px rgba(0,0,0,0.12) !important;
	border-radius: 100px;
	cursor: pointer;
}


.made-with-section .carousel-slider .arrowStyles i{
	color: #7266FB;
}

.counter-section {
	padding: 50px 0;
}

.counter-section .counter-content {
	border-left: 1px solid #E4E8EC;
    padding: 0 10px !important;
}

.counter-section .counter-content .numeric {
	color: #7266FB;
	font-family: 'Poppins', sans-serif !important;
	font-size: 48px;
	font-weight: bold;
}

.counter-section .counter-content .numeric-label {
	color: #4D4D4D;
	font-size: 16px;
	font-family: 'Poppins', sans-serif !important;
}

.form-section {
	background-color: #7266FB;
	padding: 50px 0;
}

.form-section .form-title {
    font-family: 'Poppins', sans-serif;
    font-size: 30px;
    line-height: 40px;
    font-weight: bold;
    color: #fff;
}
.form-section .form-text {
    color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    line-height: 34px;
	margin-top: 20px;
	max-width: 555px;
}
.form-section .form-elements {
    background-color: #fff;
    padding: 35px;
    border-radius: 4px;
    max-width: 457px;
    float: right;
}

/* Chrome, Safari, Edge, Opera */
.form-section .form-elements input::-webkit-outer-spin-button,
.form-section .form-elements input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.form-section .form-elements input[type=number] {
  -moz-appearance: textfield;
}

.form-section .form-elements .form-text-t {
	color: #4D4D4D;
	font-family: 'Poppins', sans-serif;
	font-size: 24px;
	font-weight: bold;
}

.form-section .form-elements .form-text-label {
	color: #818E94;
	font-family: 'Poppins', sans-serif;
	font-size: 18px;
}

.form-container form .MuiFormControl-root.MuiTextField-root{
	width: 100%;
	margin-top: 20px;
}

.form-section .invitation-btn {
	width: 100%;
    margin-top: 35px;
    background: #7266FB;
    color: #fff;
    height: 45px;
    border-radius: 50px;
}

.form-section .invitation-btn img {
	margin-right: 5px;
}

.form-section .gear-container {
	padding-right: 50px !important;
}

.bottom-df-logo {
	background-color: #F7F8FA;
	padding: 40px 0;
}

.bottom-df-logo .df-container {
    background: #fff;
    border-radius: 100px;
    text-align: center;
	padding: 30px 50px;
    display: flex;
    align-items: center;
    justify-content: center;
	/* -webkit-box-shadow: 0px 0px 33px -9px rgba(0,0,0,0.17) !important;
	-moz-box-shadow: 0px 0px 33px -9px rgba(0,0,0,0.17) !important;
	box-shadow: 0px 0px 33px -9px rgba(0,0,0,0.17) !important; */
}

.bottom-df-logo .df-container:hover {
	-webkit-box-shadow: 0px 0px 33px -9px rgba(0,0,0,0.17) !important;
	-moz-box-shadow: 0px 0px 33px -9px rgba(0,0,0,0.17) !important;
	box-shadow: 0px 0px 33px -9px rgba(0,0,0,0.17) !important;
	cursor: pointer;
}



.df-container img{
	width: 100%;
}

.sm-screen {
	display: none;
}

.carousel .thumbs {
	display: flex;
	flex-wrap: wrap;
}

.connect-section .connect-input-group button.free-start-btn .fa {
	position: relative;
	left: 5px;
}

.btn_arrow{
	font-size: 22px;
	font-weight: 600;
	position: relative;
	top: -2px;
}

.email_field_box{
	position: relative;
	max-width: 458px;
	margin: 0 auto;
	margin-top: 30px;
}

.email_field_box a{
	text-decoration: none;
}

.application_container{
	padding-top: 20px;
}

.MuiPaper-elevation1 {
	box-shadow:none !important;
}

.MuiButton-contained {
    box-shadow: none !important;
}

.center{
	text-align: center;
}

.more_btn{
	display: flex;
	justify-content: center;
	padding-top: 30px;

}

.application_logo{
	max-width: 187px;
}

.carousel .thumbs-wrapper {
	margin-bottom: 0 !important;
	margin-left: 0 !important;
}

.carousel .thumbs {
    padding: 0;
    margin: 0;
}

.quote{
	padding-left: 15px;
}

.left_align {
	margin: 0;
	margin-top: 40px;
}

.MuiInput-underline:before {
    border-bottom: #D3D8DA 1px solid;

}

.overlay{
	background: #7266FB;
	opacity: 0.6;
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
}

.slider_img {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 80%;
}
.slider_main_img {
	height: 100%;
}

.cards{
	border: #E4E8EC 1px solid;
	padding: 30px;
}

.cards .MuiCardContent-root{
	padding: 0;
}

.cards .MuiCardContent-root:last-child {
    padding-bottom: 0;
}

.deenfund-section .MuiCardContent-root:last-child{
	padding-bottom: 18px !important;
}

.cards .MuiButton-text {
    padding: 6px 0px;
    padding-bottom: 0;
}

.cards:hover {
	box-shadow: 0px 1px 1px -1px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 10px 0px rgba(0,0,0,0.12) !important;
}

.cards:hover .learn-text-btn {
	color: #7266FB !important;
}

.application_cards{
	padding: 30px;
}

.cards hr {
    margin: 10px 0 10px;
}

.cards_container{
	padding-top: 20px;
}

.left_0{
	margin-left: 0;
}

.form-container .MuiInput-input{
	
}

.form-container .MuiFormLabel-root {
    font-size: 16px !important;
	font-family: Roboto !important;
}

.form-container .MuiButton-label{
	font-family: 'Poppins', sans-serif;
	font-weight: 500;
}

.footer-links p{
	font-size: 18px;
	text-transform: uppercase;
}

.about-banner {
    width: 45%;
}

.about-left {
    max-width: 49%;
}

.floating_right{
	float: right;
}

.floating_left{
	float: left;
}

.about-home-section .MuiContainer-root {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.clearfix{
	clear: both;
}

.homeBanner .MuiGrid-container{
	align-items: center;
}

.home_Banner {
    width: 100%;
    float: right;
	max-width: 415px;
}
.form-section .MuiGrid-container{
	align-items: center;
}

.about-plain-text .MuiButton-text{
	padding: 0;
}

.empower-listInner{
	height: 271px;
}

.logoSectionHome{
	height: 40px;
}

.blueBanner .MuiGrid-container{
	align-items: center;
}

.viewMobile{
	display: none;
}

.about-home-section.MuiContainer-root {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sliderImg {
    width: 100%;
	height: 100%;
	position: relative;
}

.overlayImg {
    position: absolute;
    width: 100%;
    left: 0;
    background-color: #7266FB;
    opacity: 0.6;
    height: 100%;
    z-index: 999;
    top: 0;
}


.TabMainContainer .ListingContainer {
    overflow-y: scroll;
    height: 540px;
}









.carousel .carousel-status {
	display: none;
}

@media(max-width: 1500px){

	.home_about_img{
		width: 140%;
	}

}

.blueBanner .blue_banner {
	width: 100%;
}

.home_about_img {
	width: 100%;
}

@media(max-width: 1200px){
	.home_about_img{
		width: 100%;
	}

	.blueBanner .blue_banner {
		width: 100%;
	}

	.carousel .carousel-status {
		display: none;
	}

	

	.made-with-section .carousel-slider .arrowStyles.fwd {
		display: none;
	}

}

@media (max-width: 1090px) {
	.play-circle-img {
		display: none !important;
	}

	.about-left {
		width: 100%;
		max-width: initial;
		padding-top: 40px;
	}

	.made-with-section .carousel .thumb{
		margin: 7px;
	}
	
}

.get-text-btn {
	color: #7266FB !important;
	font-size: 14px !important;
}
@media (max-width: 990px) {

	.blueBanner  .blue_banner{
		width: 100%;
	}

	.form-section .form-elements {
	    background-color: #fff;
	    padding: 35px;
	    border-radius: 4px;
	    max-width: initial;
	    float: right;
	}

	.about-banner {
	    width: 100%;
	}
	.about-home-section .about-plain-text {
		max-width: initial;
	}

	.home_Banner {
		width: 100%;
		float: none;
	}

	.viewDekstop{
		display: none;
	}

	.viewMobile{
		display: block;
		width: 100%;
	}

	.about-home-section .MuiContainer-root {
		display: block;
	}

	.empower-listInner{
		height: auto;
	}
	
}

@media (max-width: 768px) {
	.about-home-section .connect-title {
		font-size: 24px !important;
	}
	.empower-section .empower-card {
		padding: 30px 20px;
	}
	.about-home-section .about-plain-text, .banner-plain, .blueBanner .organ-text, .connect-section .connect-text, .empower-section .empower-plain {
		font-size: 16px !important;
	}

	.blueBanner .one-text {
		font-size: 24px !important;
	}

	.blueBanner .one-text, .banner-plain {
		padding-right: 0px;
	}

	.homeBanner .text-banner .black-text, .homeBanner .text-banner .blue-text {
		font-size: 46px;
	}

	.connect-section .connect-section-content {
    	padding: 50px 10px;
	}

	.connect-input-group button.free-start-btn {
		margin-left: 0;
		height: 46px;
		max-width: initial !important;
		margin: 0 auto;
		width: 46px;
		border-radius: 100px !important;
		padding: 0 !important;
		min-width: 46px;
		right: 8px;
	}

	.connect-input-group button.free-start-btn .btn_text{
		display:none;
	}

	.btn_search span svg {
		font-size: 20px !important;
	}

	.md-screen {
		display: none;
	}

	.sm-screen {
		display: block;
	}


	.connect-input-group input {
		width: 100%;
		padding: 0 !important;
		text-indent: 20px;
	}


	.made-with-section .carousel-images {
		display: block;
		padding: 0;
	}

	.made-with-section .carousel-slider .arrowStyles.prev {
		display: none;
	}

	.made-with-section .sliderText {
		font-size: 16px;
	}

	.made-with-section .made-slider-text-section {
		padding: 24px;
		padding-top: 10px;
	}

	.connect-title {
		margin-bottom: 40px !important;
	}

	.made-with-section .made-slider-text-section {
		padding-bottom: 20px;
	}

	.made-with-section .carousel.thumbs {
		flex-wrap: wrap;
    	display: flex;
	}

	.made-with-section .carousel .thumb {
		margin-bottom: 10px;
		transform: translate3d(0px, 0px, 0px) !important;
	}

	.form-section .gear-container {
		padding-top: 10px !important;
		padding-right: 10px !important;
	}

	.form-section .form-title {
		font-size: 21px;
	}

	.form-section .form-text {
		padding-top: 15px;
	}

	.form-section .connect-input-group button {
		margin-left: -70px;
	}

	.form-section .form-elements {
		padding-left: 25px;
		padding-right: 25px;
	}

	.bottom-df-logo .MuiGrid-root.MuiGrid-item.MuiGrid-grid-sm-12.MuiGrid-grid-md-6.MuiGrid-grid-lg-3 {
		margin: 0 auto;
	}


	.connect-input-group button.free-start-btn {
		position: absolute;
		right: 5px;

	}

	.home_Banner{
		display:none;
	}

	.homeBanner .text-banner .black-text, .homeBanner .text-banner .blue-text {
		font-size: 58px;
	}

	.homeBanner {
		padding: 50px 0 50px 0;
		font-family: 'Poppins', sans-serif;
	}

	.made-with-section .carousel-images .slider_main_img{
	width: 100% !important;
}

.made-with-section .carousel-images .slider_img{
	width: 100% !important;
	padding: 30px 24px;
}


.quote {
    padding-left: 15px;
	text-align: left;
	padding-top: 10px;
}

.join-momentum .carousel .slide img {
    width: 50%;
    padding: 15px;
    display: block;
    margin: 0 auto;
}

.carousel .control-dots .dot {
    width: 15px !important;
    height: 15px !important;

}

.footerContainer .MuiGrid-item{
	width: 100% !important;
}

.homeBanner .carousel li.slide p {
	font-size: 45px;
	font-weight: 700;
	line-height: 1;
}

.made-with-section .MuiContainer-root{
	padding: 0;
}


.counter-section .counter-content {
	padding:0 10px !important;
	margin-bottom: 10px;
}

.active_section .MuiGrid-spacing-xs-3 {
    margin: 0px;
}


}

@media (max-width: 576px) {
	.homeBanner .text-banner .black-text, .homeBanner .text-banner .blue-text {
		font-size: 48px;
	}
	.bottom-df-logo .df-container {
		padding: 20px 20px;
	}

	.bottom-df-logo .df-container img {
		width: 100%;
	}

	.join-momentum .connect-title {
		max-width: 100%;
	}

	.connect-blue {
		font-size: 18px !important;
	}

	.connect-title {
		font-size: 24px !important;
	}

	.connect-section .connect-text {
		font-size: 14px;
	}

	.empower-section .empower-plain {
		max-width: 100%;
	}

	.empower-section .empower-list .MuiTypography-colorTextSecondary {
		font-size: 14px;
	}

	.empower-section .empower-card .empower-list img {
		top: 8px;
	}

	.carousel .thumbs {
		display: flex;
    	flex-wrap: wrap;
    	padding: 0;
		transform: translate3d(0, 0, 0) !important;
		margin: 0;
	}

	.counter-section .counter-content .numeric {
		font-size: 30px;
	}

	.counter-section .counter-content .numeric-label {
		font-size: 12px;
	}

	

	.counter-section {
		padding: 60px 5px;
	}

	.footerContainer .logo .footer-text {
		padding-right: 0 !important;
	}







}

@media (max-width: 599px) {
	.bottom-footer .innerContainer-content {
		justify-content: center !important;
	}

	.bottom-footer .innerContainer-content p {
		justify-content: center;
	}

	.bottom-footer .innerContainer-content div {
		padding-bottom: 0;
	}
}

@media (max-width: 540px) {

	
}

@media (max-width: 422px) {
	.bottom-df-logo .MuiGrid-root.MuiGrid-item.MuiGrid-grid-sm-12.MuiGrid-grid-md-6.MuiGrid-grid-lg-3 {
		margin: 0 auto;
	}

	
}

@media (max-width: 420px) {
.homeBanner .carousel li.slide p {
	font-size: 10vw;
}
}

li .carousel-images .slider_img .overlay {
	background: #7266FB;
    opacity: 0.6;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
