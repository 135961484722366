.integration-detail-breadcrumb {
	background-color: #F7F8FA;
}

.integration-detail-breadcrumb li a {
	color: #4d4d4d;
	font-size: 14px;
	font-weight: 500;
}

.integration-detail-breadcrumb li a.active-breadcrumb {
	color: #7266FB;
}

.integration-detail-section {
	padding: 30px 0;
}

.integration-detail-section .back-link {
    color: #4D4D4D;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
	display: block;
	margin-top: 10px;
	margin-bottom: 20px;
}
.integration-detail-section .back-link i {
    font-weight: 600;
    font-size: 19px;
    position: relative;
    top: 1px;
    margin-right: 6px;
}

.integration-detail-section .detail-dp {
    border-radius: 4px;
    height: 262px;
    margin: 10px 0 0 0;
    display: flex;
    align-items: center;
    width: 100%;
}

.integration-detail-section .detail-dp.Deenfund{
	background-color:#05ce78;
}

.integration-detail-section .detail-dp.DeenWeb{
	background-color:#f6853c;
}

.integration-detail-section .detail-dp.DeenKiosk {
    background-color: #4b95ff;
}

.integration-detail-section .detail-dp.DeenTeam{
	background-color:#4D4BE7;
}


.integration-detail-section .detail-dp .dp-container {
    margin: 0 auto;
    text-align: center;
    background-color: #fff;
    padding: 0;
    border-radius: 8px;
    width: 112px;
    height: 112px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.integration-detail-section .detail-dp .dp-container img{
	width: 68px;
	height: 68px;
}
.integration-detail-section .pricing-btn {
	background-color: #7266FB;
    color: #fff;
    border-radius: 25px;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    width: 100%;
    height: 43px;
    margin-top: 30px;
	margin-bottom: 20px;
}

.integration-detail-section .pricing-btn:hover {
	background-color: #7266FB !important;
}

.integration-detail-section .view-web-btn {
	border: 1px solid #E8E9E9;
	width: 100%;
    height: 43px;
    margin-top: 0px !important;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    background: none;
    box-shadow: none;
    border-radius: 25px;
    color: #7266FB;
}

.integration-detail-section .view-web-btn:hover {
	background-color: #7266FB !important;
	color: #fff !important;
	border:1px solid #7266FB !important;
}

.integration-detail-section .view-web-btn span{
	color: #7266FB !important;
}

.integration-detail-section .view-web-btn:hover span{
	color: #fff !important;
}

.integration-detail-section .addbtns .see-pricing-btn, .see-pricing-btn:hover{
	background-color: #7266FB !important;
}




.integration-detail-section .view-web-btn .fa {
	color: #D3D8DA;
}

.integration-detail-section .distance-top {
	margin-top: 30px;
}

.integration-detail-section .compatible-section {
	margin-top: 30px;
}

.integration-detail-section .compatible-section .compatible-t {
	color: #4D4D4D;
	font-size: 18px;
	font-family: 'Poppins', sans-serif;
	font-weight: bold;
}

.integration-detail-section .compatible-section .tags {
	font-size: 14px;
    color: #4D4D4D;
    font-family: 'Poppins', sans-serif;
    background-color: #F7F8FA;
    padding: 10px 16px;
    border-radius: 5px;
	display: inline-block;
	margin: 5px;
	font-weight: 500;
}

.integration-detail-section .integration-detail-content {
	margin-top: 8px;
}

.integration-detail-section .integration-detail-content .integ-t {
	color: #4D4D4D;
	font-family: 'Poppins', sans-serif;
	font-weight: bold;
	font-size: 26px;
}

.integration-detail-section .integration-detail-content .integ-text {
    color: #818E94;
    line-height: 34px;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    margin-top: 10px;
}

.integration-detail-section .integration-feature {
	margin-top: 25px;
}

.integration-detail-section .integration-feature .feature-t {
	color: #4D4D4D;
	font-size: 18px;
	font-family: 'Poppins', sans-serif;
	font-weight: bold;
}

.integration-detail-section .feature-content {
	display: flex;
	margin: 20px 0;
}

.integration-detail-section .feature-content img {
	width: 24px;
	height: 24px;
	margin-right: 8px;
}

.integration-detail-section .feature-content .feature-h {
	color: #4D4D4D;
	font-size: 16px;
	font-weight: 500;
	font-family: 'Poppins', sans-serif;
}

.integration-detail-section .feature-content .feature-b {
	color: #818E94;
	font-size: 14px;
	line-height: 1.6;
}

.tag_row{
	margin-left: -5px;
	margin-top: 15px;
}

.pricing-modal-parent {
	display: flex;
	align-items: center;
}

.pricing-modal {
	background-color: #fff;
	text-align: center;
	width: 100%;
	position: relative;
	max-width: 1480px !important;
	overflow-y: auto;
}


.pricing-modal .modal-pricing-table tbody {
    display:block !important;
	overflow-y:overlay;
	overflow-x: hidden;
}

@media (max-width:670px) {
	.modal-pricing-table.kiosk-pricing-modal {
		overflow-x:auto !important;
		width: 650px !important;
	}
}

@media (max-width: 1366px) {
	.pricing-modal{
		height: auto !important; 
	}
}

@media (max-width: 960px) {
	.pricing-modal .select-btn, .modal-select-btn {
		width: 90% !important; 
	}

}

.scrollbar-width-none {
	scrollbar-width: none;  
  }

.modal-pricing-table thead, 
.pricing-modal .modal-pricing-table tfoot 	,
.pricing-modal .modal-pricing-table tbody tr {
    display:table !important;
    width:100%;
    table-layout:fixed !important;/* even columns width , fix width of table too*/
}


@media (min-width: 1400px) {
	.pricing-modal {
		height: auto !important;
		max-height: 880px !important;
	}

	.pricing-modal.deenfund-pricing-modal {
		height:834px;
	}

	.pricing-modal.deenfund-pricing-modal .modal-pricing-table {
		overflow-x: hidden;
	}
}

/* width */
.pricing-modal .modal-pricing-table tbody::-webkit-scrollbar, 
.pricing-modal::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
.pricing-modal .modal-pricing-table tbody::-webkit-scrollbar-track,
.pricing-modal::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
.pricing-modal .modal-pricing-table tbody::-webkit-scrollbar-thumb,
.pricing-modal::-webkit-scrollbar-thumb {
  background: #737272; 
  border-radius: 10px;
}

/* Handle on hover */
.pricing-modal .modal-pricing-table tbody::-webkit-scrollbar-thumb:hover,
.pricing-modal::-webkit-scrollbar-thumb:hover {
  background: #737272; 
}

.pricing-modal .close_icon {
    position: absolute;
    right: 30px;
    top: 23px;
    color: #818E94;
    cursor: pointer;
    font-size: 24px;
	z-index: 1000 !important;
}

.pricing-modal td{ font-family: 'Poppins', sans-serif;}

.pricing-modal .td-price{font-family: 'Poppins', sans-serif; font-weight: 500;}

.pricing-modal .MuiCheckbox-root{
	padding: 6px;
}

.pricing-modal .coming_soon{
	color: #818E94;
}

.pricing-modal:focus {
	outline: none;
}

.top_img_popup{
	margin-top: 18px;
	width: 127px;
}


.pricing-modal .top-label {
	text-align: center;
	color: #818E94;
	font-family: 'Poppins', sans-serif;
	font-size: 12px;
	margin-bottom: 18px;
}

.modal-label {
	color: #4D4D4D !important;
	font-size: 18px !important;
	font-family: 'Poppins', sans-serif !important;
	font-weight: bold !important;
	margin: 5px auto !important;
}

.modal-label-price {
	font-size: 26px !important;
	font-family: 'Poppins', sans-serif !important;
	margin: 5px auto !important;
	font-weight: 500 !important;
	color: #4D4D4D !important;
}

.modal-label-plan {
	color: #818E94 !important;
	font-size: 14px !important;
	margin: 5px auto !important;
	margin-bottom: 20px !important;
}

.modal-select-btn {
	background-color: #7266FB !important;
    color: #fff !important;
	border-radius: 25px !important;
	height: 36px;
	width: 148px;
	margin-bottom: 8px !important;
}

/* width */
.modal-pricing-table::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
.modal-pricing-table::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
.modal-pricing-table::-webkit-scrollbar-thumb {
  background: #737272; 
  border-radius: 10px;
}

/* Handle on hover */
.modal-pricing-table::-webkit-scrollbar-thumb:hover {
  background: #737272; 
}

.modal-pricing-table {
	height: auto;
	box-shadow: none;	
}

.modal-pricing-table table {
	border-collapse: collapse;
}

.modal-pricing-table td {
	position: relative;
	border:1px solid #E4E8EC;
}

.modal-pricing-table th {
    position: relative;
    border: 1px solid #E4E8EC;
}

.most-left p {
	display: contents;
	font-weight: 500;
    font-size: 14px;
}

.most-left img {
	position: absolute;
	right: 10px;
}

.MuiCheckbox-root {
	color: #4D4D4D !important;
}

.Mui-checked{
	color: #05CE78 !important;
}

.monthly-l {
	color: #7266FB;
	/* font-size: 14px; */
	font-family: 'Poppins', sans-serif;
	font-weight: 500;
}

.billed-a {
	color: #4D4D4D;
	display: inline-block;
	font-family: 'Poppins', sans-serif;
	font-weight: 600;
}

.save-t {
    color: #05ce78;
    font-weight: 400;
    padding-top: 9px  !important;
    position: absolute;
    margin-top: -9px;
	float: none;
}

.save-t_2 {
    color: #05ce78;
    font-weight: 400;
    padding-top: 3px;
    position: absolute;
    margin-top: -9px;
    right: 87px;
}


.monthly-left {
	color: #4D4D4D;
	font-size: 14px;
	text-transform: capitalize;
}

.package_card{
	cursor: pointer;
	height: 100px;
}



.modal_info_icon{
	position: relative;
	top: 7px;
}

.pricing-modal .MuiTableCell-root {
    padding: 14px 22px !important;
}

.PageLinksContainer a{
	text-transform: uppercase !important;
}

.billed_monthly {
	width: 250px !important;
}

.MuiTableCell-body.most-left{
	width: 250px !important;
}

@media (max-width: 600px) {
	.integration-detail-section .detail-dp {
		margin: 20px auto 10px;
	}

	.integration-detail-breadcrumb li a {
		font-size: 12px;
	}

	.integration-detail-section .integration-detail-content .integ-text {
		font-size: 14px;
	}
}

@media (max-width: 650px) {
	.integration-detail-section .view-web-btn {
		font-size: 12px;
	}
}