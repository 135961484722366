.stepper-info {
	background-color: #F7F8FA !important;
	padding: 40px 0;
}

.stepper-info .MuiStepLabel-horizontal {
	display: block;
}

.stepper-info .MuiStepLabel-iconContainer {
	justify-content: center;
	padding-right: 0;
}

.stepper-info .MuiStepLabel-label {
	margin-top: 10px;
	font-family: 'Poppins', sans-serif;
	font-weight: 500;
	font-size: 14px;
}

.stepper-info .MuiStepConnector-horizontal {
	position: relative;
    bottom: 14px;
}

.stepper-info .stepper-count {
	background-color: transparent;
	max-width: 409px;
	margin: 0 auto;
	padding: 20px;
}

.stepper-info .here-help {
	color: #4D4D4D;
	font-family: 'Poppins', sans-serif;
	font-size: 30px;
	font-weight: bold;
	text-align: center;
	
}

.easy-platform {
    color: #818E94;
    font-family: 'Poppins', sans-serif !important;
    font-size: 18px !important;
	text-align: center;
	margin-top: 10px !important;
}


.stepper-info .MuiSvgIcon-root{
	width: 32px;
    height: 32px;
}
/* 
.stepper-info .MuiSvgIcon-root.MuiStepIcon-root {
	color: #fff;
    background-color: #2A82F6;
    border-radius: 50%;
    padding: 1px;
} */

.stepper-info .MuiStepIcon-text {
	/* fill: #2A82F6 !important; */

}

.stepper-info .Mui-disabled .MuiSvgIcon-root.MuiStepIcon-root {
	fill: #2A82F6 !important;
}

.MuiStep-horizontal .MuiStepLabel-horizontal.Mui-disabled .MuiStepLabel-iconContainer svg {
	fill: #fff !important;
	border:1px solid #2A82F6  !important;
	border-radius: 50%;
}

.MuiStep-horizontal .MuiStepLabel-horizontal.Mui-disabled .MuiStepLabel-iconContainer svg .MuiStepIcon-text {
	fill: #2A82F6 !important;
}

.stepper-info .MuiStepIcon-root.MuiStepIcon-active {
    fill: #2A82F6 !important;
    width: 32px;
    height: 32px;
}

.stepper-info .MuiStepIcon-root.MuiStepIcon-completed {
	fill: #fff;
    background-color: #3CC480;
    border-radius: 50%;
    padding: 1px;
}

.MuiButton-containedPrimary {
	background-color: #7266FB;
	border-radius: 50px !important;
}

.schedule-demo-divider {
	margin-top: 104px !important;
    margin-bottom: 25px !important;
}

.sticky-proceed-footer-wrapper {
	
}

.sticky-proceed-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    padding: 30px 0;
    border-top: #E4E8EC 1px solid;
}

.card-step-container {
    justify-content: space-between;
    max-width: 900px;
    margin: 0 auto;
    padding-bottom: 90px;
}
.proceed-btn{
	height: 40px;
	width: 166px;
}


.proceed-btn svg{
	width: 15px;
}


.go-back-btn svg{
	width: 15px;
}


@media (max-width: 576px) {
	.stepper-info .here-help {
		font-size: 24px;
	}

	.stepper-info .easy-platform {
		font-size: 14px;
	}

	.stepper-info .stepper-count {
		padding: 10px;
	}

	.stepper-info .MuiStep-completed {
		padding: 0;
	}
}

@media (max-width: 599px) {
	.card-step {
	    margin: 10px !important;
	}
}



@media (min-width: 600px) {
	.card-step.MuiGrid-grid-sm-6 {
    	flex-grow: 0;
    	max-width: 48%;
    	flex-basis: 48%;
	}
}

@media (min-width: 960px) {
	.card-step.MuiGrid-grid-md-4 {
    	flex-grow: 0;
    	max-width: 32.333333%;
    	flex-basis: 32.333333%;
	}
}

.MuiStep-horizontal {
    padding-left: 0;
    padding-right: 0;
}

