@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic&family=Open+Sans&family=Poppins:ital,wght@0,300;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,500;1,600;1,700;1,800;1,900&display=swap');

.BlogListingMainContainer {}


.BlogListingCardLarge {
    position: relative;
    border: #E4E8EC 1px solid;
}

.BlogListingCardLarge .BlogListingCardLargeImg {
    max-height: 420px;
    overflow: hidden;
}

.BlogListingCardLarge .BlogListingCardLargeImg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.BlogListingMainContainer {
    max-width: 1140px;
    margin: 0 auto;
}

.BlogListingMainContainer .BlogListingCardLargeInfo {
    padding: 32px;
}


.BlogListingMainContainer .BlogListingCardLargeInfo .BlogListingCardLargeInfoPost {
    color: #818E94;
    font-size: 12px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    font-family: 'Poppins', sans-serif;
}

.BlogListingMainContainer .BlogListingCardLargeInfo .BlogListingCardLargeInfoPost span {
    display: inline-block;
    width: 5px;
    height: 5px;
    background: #D3D8DA;
    border-radius: 100px;
    margin: 0 5px;
}

.BlogListingMainContainer .BlogListingCardLargeInfo h1 {
    font-size: 38px;
    color: #39337E;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
}

.BlogListingMainContainer .BlogListingCardLargeInfo .BlogReadMoreButton {
    display: flex;
    color: #818E94;
    font-size: 14px;
    text-transform: uppercase;
    align-items: center;
    font-family: 'Poppins', sans-serif;
}

.BlogListingMainContainer .BlogListingCardLargeInfo .BlogReadMoreButton svg {
    font-size: 13px;
    margin-left: 5px;

}


.BlogListingSection {
    margin-top: 12px;
    margin-bottom: 30px;
}


.BlogListingCardSmall {
    position: relative;
    border: #E4E8EC 1px solid;
    cursor: pointer;
}

.BlogListingCardSmall a:hover {
    text-decoration: none !important;
}

.BlogListingCardSmall .BlogListingCardSmallImg {
    overflow: hidden;
    height: 200px;
}

.BlogListingCardSmall .BlogListingCardSmallImg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.BlogListingCardSmall .BlogListingCardSmallInfo {
    padding: 20px;
    min-height: 120px;
}

.BlogListingCardSmall .BlogListingCardSmallInfo .BlogListingCardSmallInfoPost {
    color: #818E94;
    font-size: 12px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    text-decoration: none;
}

.BlogListingCardSmall .BlogListingCardSmallInfo .BlogListingCardSmallInfoPost span {
    display: inline-block;
    width: 5px;
    height: 5px;
    background: #D3D8DA;
    border-radius: 100px;
    margin: 0 5px;
}

.BlogListingCardSmall .BlogListingCardSmallInfo h1 {
    font-size: 19px;
    color: #39337E;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    line-height: 25px;
    text-decoration: none;
    margin-top: 25px;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.BlogListingCardSmall .BlogListingCardSmallInfo .BlogSmallCardReadMoreButton {
    display: flex;
    color: #818E94;
    font-size: 14px;
    text-transform: uppercase;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    text-decoration: none;
    transition: 0.3s;
}

.BlogListingCardSmall .BlogListingCardSmallInfo .BlogSmallCardReadMoreButton svg {
    font-size: 13px;
    margin-left: 5px;

}

.BlogListingCardSmall {
    transition: 0.3s;
}

.BlogListingCardSmall:hover {
    box-shadow: 0px 12px 17px #0000000F;
}

.BlogListingCardSmall:hover .BlogSmallCardReadMoreButton {
    color: #39337E;
    font-weight: 600;
}

.LoadmoreButtonContainer {
    background: #E6FAF1;
    color: #05CE78;
    cursor: pointer;
    padding: 15px;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
}

.TabMainContainer .MuiTabs-flexContainer {
    background-color: transparent;
    font-family: 'Poppins', sans-serif;
    justify-content: center;
    border-bottom: #E4E8EC 1px solid;
}

.TabMainContainer .MuiTabs-root .MuiTabs-scroller .MuiTabs-indicator {
    background-color: #7266FB !important;
}

.TabMainContainer .MuiButtonBase-root .MuiTab-wrapper {
    text-align: left !important;
    font-weight: 600;
}




.BlogListingRecentCardSmall .BlogListingCardSmallInfo {
    padding: 20px;
}


.BlogListingRecentCardSmall .BlogListingCardSmallInfo .BlogListingCardSmallInfoPost {
    color: #818E94;
    font-size: 12px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    text-decoration: none;
}

.BlogListingRecentCardSmall .BlogListingCardSmallInfo .BlogListingCardSmallInfoPost span {
    display: inline-block;
    width: 5px;
    height: 5px;
    background: #D3D8DA;
    border-radius: 100px;
    margin: 0 5px;
}

.BlogListingRecentCardSmall .BlogListingCardSmallInfo h1 {
    font-size: 20px;
    color: #39337E;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    line-height: 28px;
    text-decoration: none;
}

.BlogListingRecentCardSmall {
    transition: 0.3s;
    cursor: pointer;
}

.BlogListingRecentCardSmall:hover {
    box-shadow: 0px 12px 17px #0000000F;
}

.BlogListingRecentCardSmall:hover h1 {
    color: #7266FB;
}

.TabMainContainer .MuiTabs-root {
    position: relative;
    left: 20px;
    margin-bottom: 15px;
}

.BlogListingRecentCardSmall a {
    text-decoration: none;
}
/* --------------------------------------------------- */


.how-it-work-section .how-title {
    font-family: 'Poppins', sans-serif;
    font-size: 45px;

}


.InnerPageContentSection {
    max-width: 750px;
    margin: 0 auto;
}

.autherAndSocialDetails {
    display: flex;
    justify-content: space-between;
    border-bottom: #E4E8EC 1px solid;
    margin-bottom: 25px;
    margin-top: 25px;
    padding-bottom: 25px;
}

.autherAndSocialDetails .autherInfo {
    display: flex;
}

.autherAndSocialDetails .autherInfo .autherIcon {
    background: #EEF6F1;
    width: 44px;
    height: 44px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #56B149;
    margin-right: 12px;
}

.autherAndSocialDetails .autherInfo h2 {
    font-size: 14px;
    font-weight: 400;
    color: #444444;
    margin: 0;
    font-family: 'Poppins', sans-serif !important;
}

.autherAndSocialDetails .autherInfo p {
    font-size: 14px;
    font-weight: 400;
    color: #676C7B;
    margin: 0;
    font-family: 'Poppins', sans-serif !important;
}

.autherAndSocialDetails .SocialIcon a img {
    width: 44px;
    margin: 0 5px;
    cursor: pointer;
}

.ArticleContentsContainer p{
	font-size:18px !important;
	 color: #484a4d !important; 
	line-height: 34px !important;
	font-weight: 400 !important;
    font-family: 'Poppins', sans-serif;
    margin-top: 14px !important;
    margin-bottom: 25px;

}

.ArticleContentsContainer .Quots {
    border-left: #8349e1 4px solid;
    font-style:bold;
    padding-left: 19px;
    color: #616567;
}


.ArticleContentsContainer .articleImg {
    height: 320px;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 50px;
   
}

.ArticleContentsContainer .articleImg img {
    object-fit: cover;
    width: 100%;
    height: 105%;
    /* border-top: 5px solid  #8349e1; */
  
}

.ArticleContentsContainer h2 {
    font-size: 29px;
    font-family: 'Poppins', sans-serif;
    color:  #8349e1;
    font-weight: 700;
    line-height: 20px;
    margin-top: 30px;
    border-bottom: 1px solid #e8e5ff;
    padding-bottom: 22px;
}

.ArticleContentsContainer .youtubevideo {
    margin-top: 25px;
}

.ArticleContentsContainer .articleperagraph.ArtList {
    display: flex;
    align-items: baseline;
}

.ArticleContentsContainer .articleperagraph.ArtList svg {
    font-size: 10px;
    margin-right: 12px;
}

.ArticleContentsContainer h4 {
    font-size: 27px !important;
    line-height: 28px !important;
    font-weight: 600 !important;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 7px;
    color: #47494f;

}

.ArticleSubTitle .MuiSvgIcon-root {
    width: 0.5em;
    height: 1em;
    color:  #8349e1;
    display: inline-block;
    margin-right: 5px;
    font-size: 1rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    -webkit-user-select: none;
    user-select: none;
    padding-top: 4px;

}

.articleperagraph .MuiSvgIcon-root {
    width: 0.5em;
    height: 1em;
    color:  #8349e1;
    display: inline-block;
    font-size: 1rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    -webkit-user-select: none;
    user-select: none;
    padding-top: 13px;


}

.articleperagraph {
    font-size: 18px !important;
    line-height: 16px !important;
    font-weight: 600 !important;
    font-family: 'Poppins', sans-serif;
    color: #47494f;
    margin-top: 6px;
}


.articleperagraph a {
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    padding-left: 4px;
    padding-right: 4px;
    text-decoration: underline;
    color:#6249e1;
    font-weight: 700px;

}

.articleperagraph a:hover {
    cursor: pointer;
    color: #ee9e0b;
    text-decoration: underline;

}


.ArticleSubTitle_Icon .MuiSvgIcon-root {
    width: 0.5em;
    height: 0.5em;
    color:#8349e1;
    display: inline-block;
    font-size: 1rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    -webkit-user-select: none;
    user-select: none;
}

.ArticleSubTitle h3 {
    line-height: 10px;
    font-size: 22px;

}

.ArticleSubTitle h4 {
    margin-bottom: 2px;
    font-size: 10px;


}

.ArticleSubTitle h5 {
    margin-top: 15px;
    margin-bottom: 8px;
    color: #515253;
    font-size: 27px;


}

.ArticleSubTitle h6 {
    font-size: 30px;
    font-family: 'Poppins', sans-serif;
    line-height: 22px;
    margin-top: 29px;
    margin-bottom: 10px;


}

.ArticleSubTitle h1 {
    font-size: 30px;
    font-family: 'Poppins', sans-serif;
    color: #8349e1;
    font-weight: 700;
}


/* Article Details CSS END */