.stepper-tabs-container {
	margin-bottom: 130px;
}

.stepper-tabs-container .stepertitle1{
	padding: 0 24px;
}

.stepper-tabs-container .stepper-tabs-content ul {
	justify-content: space-between;
	flex-wrap: wrap;
}

.stepper-tabs-container .stepper-tabs-content ul li {
	width: 53px;
	text-align: center;
	color: #818E94;
	font-family: 'Poppins', sans-serif;
	font-size: 14px;
	font-weight: 500;
}

.stepper-tabs-container .stepper-tabs-content ul li.active {
	color: #7266FB;
}

.stepper-tabs-container .stepper-tabs-content .bg-success {
	background-color: #7266FB;
}

.stepper-tabs-container .stepper-tabs-content .selected-appointment.time-section {
	border:1px solid #7266FB !important;
}

.stepper-tabs-container .stepper-tabs-content .MuiTabs-indicator {
	display:none !important;
}

.MuiButtonBase-root.Mui-selected .MuiTab-wrapper {
	color: #7266FB !important;
	/* margin-left: 0px !important; */
}

.stepper-tabs-container .stepper-tabs-content .MuiTab-root {
	border-bottom: 1px solid #eee;
}

.MuiButtonBase-root.Mui-selected {
	border-bottom:2px solid #7266FB !important;
}

.stepper-tabs-content .MuiTabs-flexContainer {
	flex-wrap: wrap;
	background-color: transparent;
}
.stepper-tabs-container .stepper-tabs-content .time-section {
	color: #4D4D4D;
	text-align: center;
	font-family: 'Poppins', sans-serif;
	font-size: 14px;
	font-weight: 500;
	border: 1px solid #E4E8EC;
    border-radius: 5px;
    margin-right: 25px;
    margin-bottom: 15px;
	position: relative;
	height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.stepper-tabs-container .stepper-tabs-content .time-section-unavailable {
	background-color: #E4E8EC;
}

.stepper-tabs-container .stepper-tabs-content .time-section img {
	position: absolute;
    right: 5px;
    top: 3px;
}

.stepper-tabs-container .stepper-tabs-content .day-section {
	margin-top: 10px;
    margin-left: 0;
}


.stepper-tabs-container .stepper-tabs-content .time-section:hover {
	background-color: #E4E8EC;
	cursor: pointer;
}

.time-above {
	color: #828282;
	font-family: 'Poppins', sans-serif !important; 
	font-style: italic !important;
	font-size: 14px !important;
	margin: 20px 0 !important;
}

.finish-title {
	color: #444444 !important;
	font-family: 'Poppins', sans-serif !important;
	font-size: 30px !important;
	font-weight: bold !important;
	text-align: center !important;
}

.finish-plain-text {
	color: #828282;
	font-family: 'Poppins', sans-serif !important;
	font-size: 14px !important;
    margin: 10px auto !important;
}

.finish-divider {
	margin:20px auto !important;
}

.sch-date-t {
	color: #4D4D4D !important;
	font-family: 'Poppins', sans-serif !important;
	font-weight: 600 !important;
	font-size: 14px !important;
	text-align: center !important;
}

.sch-time {
	color: #4D4D4D !important;
	font-family: 'Poppins', sans-serif !important;
	font-weight: 600 !important;
	font-size: 24px !important;
	text-align: center !important;
}

.add-calendar-container {
    text-align: center;
    margin: 25px auto;
}

.add-calendar-container button {
	background-color: #7266FB !important;
	color: #fff !important;
	font-family: 'Poppins', sans-serif !important;
	font-weight: 500 !important;
	font-size: 14px !important;
	border-radius: 50px !important;
	width: 184px;
    height: 40px;
}

.stepper-tabs-container .stepper-tabs-content .time-section .tooltip-btn-container {
	position: unset !important;
	color: #4D4D4D;
	padding: 0;
}

.stepper-tabs-container .stepper-tabs-content .time-section .tooltip-btn-container:hover {
	background:none;
}

.textCenter{
	text-align: center;
}

.schEmailText{
	font-weight: 600;
}

@media (min-width: 600px) {
	.stepper-tabs-container .stepper-tabs-content .time-section {
    	flex-grow: 0;
    	max-width: 48% !important;
	    flex-basis: 48% !important;
	    margin: 5px !important;
	}

	.finish-plain-text {
		max-width: 50%;
	}

	.finish-divider {
		max-width: 50%;
	}
}



@media (min-width: 960px) {
	.stepper-tabs-container .stepper-tabs-content .time-section {
    	flex-grow: 0;
    	max-width: 24% !important;
	    flex-basis: 24% !important;
	    margin: 5px !important;
	}
}