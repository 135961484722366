.pricing-modal-parent {
	display: flex;
	align-items: center;
}

.hardware-details-modal {
	background-color: #fff;
	text-align: left;
	width: 100%;
	position: relative;
	max-width: 1140px;
	height: 630px;
	overflow-y: auto;
	margin-left: 20px;
	outline: none;
	border-radius: 4px;
}

.hd-title {
    color: #4D4D4D;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 18px;
    display: block;
    text-align: center;
}

.model_backBtn{
	position: absolute;
}


@media (min-width: 1400px) {
	.hardware-details-modal {
		height: 780px;
	}
}

/* width */
.hardware-details-modal::-webkit-scrollbar {
  	width: 6px;
  	height: 6px;
}

/* Track */
.hardware-details-modal::-webkit-scrollbar-track {
  	box-shadow: inset 0 0 5px grey; 
  	border-radius: 10px;
}
 
/* Handle */
.hardware-details-modal::-webkit-scrollbar-thumb {
  	background: #737272; 
  	border-radius: 10px;
}

/* Handle on hover */
.hardware-details-modal::-webkit-scrollbar-thumb:hover {
  	background: #737272; 
}

.hardware-details-modal .close_icon {
    color: #818E94;
    cursor: pointer;
    font-size: 24px;
    position: absolute;
    top: 30px;
    right: 30px;
}
.hardware-details-modal .top-label {
	color: #818E94;
	font-family: 'Poppins', sans-serif;
	font-size: 12px;
	padding-bottom: 18px;
	margin-top: 5px;
	text-align: center;
}

.hardware-top-section {
	justify-content: space-between;
	padding-top: 24px;
	align-items: center;
    padding-right: 10px;
}

.hardware-top-section button {
    border-radius: 25px;
    position: relative;
    left: 50px;
    width: 86px;
    height: 36px;
    display: flex;
    align-items: center;
}

.hardware-body-section .carousel .thumbs {
	transform: translate3d(0px, 0px, 0px) !important;
	padding-left: 0;
}

.hardware-body-section .carousel .thumbs li img{
	width: 64px;
	height: 64px;
	border-radius: 4px;
}

.hardware-body-section .carousel .thumb {
	border-radius: 15px;
}

.hardware-body-section .carousel .control-dots {
	display: none !important;
}

.hardware-body-section .carousel .control-arrow {
	opacity: 1;
}

.hardware-body-section .carousel.carousel-slider .control-arrow, .carousel.carousel-slider .control-arrow:hover {
	background: #000 !important;
	opacity: .4 !important;
}




@media (max-width: 960px) {
	.hardware-body-section .hardware-right-content .select-opt button {
		margin-top: 20px;
	}
}

.hardware-body-section .hardware-right-content .right-title {
	color: #4D4D4D;
	font-size: 30px;
	font-weight: bold;
	text-align: left;
	font-family: 'Poppins', sans-serif;
}

.hardware-body-section .hardware-right-content .price-plan {
	color: #4D4D4D;
	font-weight: 500;
	font-size: 26px;
	font-family: 'Poppins', sans-serif;
	text-align: left;
	padding-top: 5px;
}

.hardware-body-section .hardware-right-content .light-price-color {
	color: #818E94;
	font-size: 20px;
}

.hardware-body-section .hardware-right-content .hardware-desc {
	color: #818E94;
	font-family: 'Poppins', sans-serif;
	font-size: 16px;
	text-align: left;
    padding-top: 20px;
    line-height: 1.8;
}

.hardware-body-section .hardware-right-content .plus-one {
	color: #4D4D4D;
	font-family: 'Poppins', sans-serif;
	font-size: 14px;
	font-weight: 500;
    padding-top: 20px;
    text-align: left;
}

.hardware-body-section .hardware-right-content .select-btn-container {
	padding-top: 25px;
}

.hardware-body-section .hardware-right-content .hard-select-box {
	width: 100%;
	text-align: left;
}

/* .hardware-body-section .hardware-right-content .hard-select-box div, .hard-select-box-cont {
	
} */

.hard-select-box-cont .MuiOutlinedInput-input{
	height: 46px !important;
	width: 206px !important;
	padding: 0 10px !important;
}

.MuiOutlinedInput-input {
    padding: 16.5px 14px;
}

.hardware-body-section .hardware-right-content .select-opt button {
	background: #7266FB;
    width: 206px;
    height: 46px;
    float: left;
}

.hardware-body-section .hardware-right-content .select-opt button span{
	font-size: 14px;
}

.hardware-body-section .hardware-right-content .hard-right-divider {
	margin: 25px 0;
}

.hardware-body-section .hardware-right-content .feature-t {
	color: #4D4D4D;
	text-align: left;
	font-family: 'Poppins', sans-serif;
	font-weight: bold;
	font-size: 18px;
}

.hardware-body-section .hardware-right-content .feature-list ul {
	padding-left: 18px;
}

.hardware-body-section .hardware-right-content .feature-list ul li {
	color: #818E94;
	text-align: left;
	font-family: 'Poppins', sans-serif;
	font-size: 16px;
	line-height: 1.8;
}

.hardware-body-section .include-t {
	color: #4D4D4D;
	font-family: 'Poppins', sans-serif;
	font-weight: bold;
	font-size: 18px;
	text-align: left;
}

.hardware-body-section .package-line {
	display: flex;
	padding: 10px;
}

.hardware-body-section .available-packages {
	border: 1px solid #eee;
    margin-top: 20px;
    border-radius: 5px;
}

.hardware-body-section .package-text .package-title {
    color: #4D4D4D;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    text-align: left;
    margin-left: 7px;
    font-weight: 500;
}
.hardware-body-section .package-text {
	padding-left: 0px;
    padding-top: 20px;
}

.hardware-body-section .package-text .learn-m {
    color: #818E94;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    padding: 1px 8px;
}
.hardware-body-section {
	position: relative;
	top: 50px;
}

.hardware-details-number-of-devices-input-label .MuiInputLabel-outlined {
    font-size: 14px;
}

@media (min-width: 1400px) {
	.hardware-details-modal {
		height: 780px;
	}
}

@media (max-width: 576px) {
	.hardware-body-section {
		position: relative;
		top: 20px;
	}

	.hardware-details-modal {
		width: 95%;
		height: 560px;
	}

	.hardware-body-section .hardware-right-content .select-opt button {
		widows: 90%;
		margin-top: 35px;
	}

	.hardware-top-section button {
		left: 5px;
		font-size: 11px;
	}

	.hardware-top-section button svg {
		width: 15px;
	}

}