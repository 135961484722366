.search-input-box {
	margin: 20px auto !important;
	position: relative;
	left: -3%;
	max-width: 85%;
}

.search-input-box input {
	width: 100%;
    height: 48px;
    border-radius: 70px;
    border: none;
    color: #818E94;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    padding-left: 45px;
}

.search-input-box img {
	position: absolute;
    top: 15px;
    left: 15px;
}

.support-section .choose-title, .popular-topics .choose-title  {
	color: #4D4D4D;
	font-family: 'Poppins', sans-serif;
	font-weight: bold;
	font-size: 26px;
	text-align: left;
	margin-bottom: 18px;
}

.support-container .speed-img-container {
	width: 76px;
    height: 76px;
    display: inline-block;
    line-height: 95px;
    background: #f4f3ff;
    border-radius: 60px;
}

.support-container .speed-heading {
	color: #4D4D4D;
	font-weight: 500;
	font-size: 26px;
	margin: 10px 0;
}

.support-container .speed-text {
	color: #818E94;
	font-family: 'Poppins', sans-serif;
	font-size: 16px;
}

.popular-links {
	margin-bottom: 107px !important;
	padding-top: 0 !important;
}

.popular-links ul {
	padding-left: 15px;
	margin-top: 0;
}

.popular-topics li {
	color: #4D4D4D;
    margin: 15px 0;
    text-align: left;
}

.popular-topics li a {
	color: #7266FB;
	font-family: 'Poppins', sans-serif;
	font-size: 18px;
}

.popular-topics li a:hover {
	text-decoration: underline;
}

.find-looking-content {
	background-color: #fff;
	padding: 20px;
	box-shadow: 2px 2px 6px 5px #0000000F;
	height: 100%;
	border-radius: 4px;
}

.find-looking-content .find-looking-text {
	text-align: left;
}

.find-looking-content .find-looking-text .find-looking-title {
	color: #4D4D4D;
	font-family: 'Poppins', sans-serif;
	font-weight: bold;
	font-size: 30px;
}

.find-looking-content .find-looking-text .find-looking-plain {
	color: #818E94;
	font-family: 'Poppins', sans-serif;
	font-size: 18px;
	margin-top: 10px;
}

.find-looking-content .find-contact-btn {
    margin-top: 45px;
}

.find-looking-content .find-contact-btn button {
	background-color: #7266FB;
    color: #fff;
    border-radius: 52px;
    font-family: Roboto;
    font-size: 14px;
    height: 45px;
    width: 143px;
}

.looking-container {
	padding: 25px 0 80px;
    text-align: center;
    background-color: #F7F8FA;
}

.support-detail-breadcrumb {
	background-color: #F7F8FA;
}

.support-detail-breadcrumb li a {
	color: #4d4d4d;
	font-size: 14px;
	font-weight: 500;
	font-family: 'Poppins', sans-serif;
}

.support-detail-breadcrumb li a.active-breadcrumb {
	color: #7266FB;
}

.query-accordian #panel1a-content button {
	max-width: fit-content !important;
}

.query-accordian #panel1a-header {
	max-width: 360px;
	margin-right: auto;
	/* border-right: 1px solid rgba(0, 0, 0, 0.12); */
    padding-right: 0;
}

.query-accordian #panel1a-content {
	padding-left: 0;
}

.query-accordian #panel1a-content .MuiAccordionDetails-root {
	padding-top: 0px;
	padding-bottom: 0px;
}

.query-accordian #panel1a-content .MuiTab-root {
	min-width: 160px;
    max-width: 100%;
    text-align: left;
}

.query-accordian #panel1a-content .MuiTab-root .MuiTab-wrapper {
	text-align: left;
	display: block;
}

.query-accordian .MuiTabs-vertical {
	/* min-width: 390px; */
	/* min-width: 331px; */
	width:100%;
}

.query-accordian .sub-query-title {
	color: #4D4D4D;
	font-family: 'Poppins', sans-serif;
	font-size: 18px;
	font-weight: 600;
}

.query-accordian .MuiTabs-flexContainer {
	background-color: transparent;
}

.query-accordian .MuiTabs-flexContainerVertical button {
	padding-left: 0;
	min-height: 53px;
	padding-top: 0;
	padding-bottom: 0;
}

.query-accordian .MuiTabs-flexContainerVertical button .MuiTab-wrapper {
	font-family: 'Poppins', sans-serif;
	font-size: 16px;
	color: #4D4D4D;
}

.query-accordian .MuiTabs-flexContainerVertical button.Mui-selected .MuiTab-wrapper {
	color: #7266FB !important;
}

.query-accordian .MuiButtonBase-root.Mui-selected {
    border-bottom: transparent !important;
}

.query-accordian .PrivateTabIndicator-colorSecondary-9 {
	background-color: transparent;
}

.query-accordian .MuiTab-labelIcon .MuiTab-wrapper > *:first-child {
	margin-bottom: 0;
}

.query-accordian .MuiTab-labelIcon .MuiTab-wrapper svg {
	position: relative;
	top: 6px;
	right: 5px;
}

.query-accordian .makeStyles-root-5 {
	height: auto;
}

.query-accordian .MuiPaper-root.MuiAccordion-root.Mui-expanded.MuiAccordion-rounded.MuiPaper-elevation1.MuiPaper-rounded {
	border-top: none !important;
	border-bottom: none !important;
	margin-top: 0px;
	margin-bottom: 0px;
	overflow: hidden;
}

.query-accordian {
	margin-top: 40px;
}

.query-accordian .tab-content-container {
	margin-top: 50px;
}

.query-accordian .tab-content-container .MuiBox-root {
	padding-top: 0px !important;
	padding-right: 0px !important;
}

.query-accordian .tab-content-container .set-up-organization-container .set-up-title {
	color: #4D4D4D;
	font-family: 'Poppins', sans-serif;
	font-weight: bold;
	font-size: 26px;
	margin-bottom: 25px;
}

.query-accordian .tab-content-container .set-up-organization-container .set-up-text {
	color: #4D4D4D;
	font-family: 'Poppins', sans-serif;
	font-size: 16px;
}

.query-accordian .tab-content-container .set-up-organization-container .on-page-title {
	color: #4D4D4D;
	font-family: 'Poppins', sans-serif;
	font-weight: bold;
	font-size: 18px;
	margin: 20px 0 10px;
}

.query-accordian .tab-content-container .set-up-organization-container .on-page-lists ul {
	padding-left: 15px;
}

.query-accordian .tab-content-container .set-up-organization-container .on-page-lists ul li a {
	color: #4D4D4D;
	font-family: 'Poppins', sans-serif;
	font-size: 16px;
	text-decoration: underline;
}

.query-accordian .tab-content-container .set-up-organization-container .on-page-lists ul li a:hover {
	color: #7266FB;
}

.query-accordian .tab-content-container .set-up-organization-container .on-page-lists ul li {
	margin: 15px 0;
	text-align: left;
}

.query-accordian .tab-content-container .set-up-organization-container .our-divider {
	margin: 35px 0;
}

.query-accordian .tab-content-container .PrivateTabIndicator-colorSecondary-10, .query-accordian .tab-content-container .PrivateTabIndicator-colorSecondary-43 {
	background-color: none !important;
}

.query-accordian .tab-content-container .set-up-organization-container .share-ein ul {
	padding-left: 15px;
}

.query-accordian .tab-content-container .set-up-organization-container .share-ein ul li {
	color: #4D4D4D;
	font-family: 'Poppins', sans-serif;
	font-size: 16px;
	margin: 15px 0;
	text-align: left;
}

.query-accordian .tab-content-container .set-up-organization-container .pro-tip {
	border-left: 4px solid #05CE78;
	background-color: #EFFBF5;
	padding: 15px;
	margin: 35px 0;
}

.query-accordian .tab-content-container .set-up-organization-container .pro-tip .pro-tip-title {
	color: #4D4D4D;
	font-family: 'Poppins', sans-serif;
	font-weight: bold;
	font-size: 18px;
	padding-top: 10px;
}

.query-accordian .tab-content-container .set-up-organization-container .pro-tip .pro-tip-text {
	color: #4D4D4D;
	font-family: 'Poppins', sans-serif;
	font-size: 14px;
	margin-top: 5px;
	line-height: 1.8;
}

.query-accordian .tab-content-container .set-up-organization-container .login-steps {
	color: #4D4D4D;
	font-family: 'Poppins', sans-serif;
	font-weight: bold;
	font-size: 16px;
	margin: 20px 0;
}

.query-accordian .tab-content-container .set-up-organization-container .login-step-img {
	width: 100%;
	margin:20px 0;
}

.query-accordian .tab-content-container .set-up-organization-container .upload-logo-video {
	width: 100%;
}

.query-accordian .tab-content-container .set-up-organization-container .upload-logo-video iframe {
	width: 100%;
    height: 367px;
}

.query-accordian .tab-content-container .set-up-organization-container .last-update-date {
	color: #4D4D4D;
	font-family: 'Poppins', sans-serif;
	font-size: 12px;
	margin: 20px 0;
}

.query-accordian .tab-content-container .set-up-organization-container .set-up-text a {
	color: #7266FB;
	font-size: 16px;
}

.query-accordian .MuiAccordion-root:before {
	height: 0px !important;
}

.query-accordian .tab-content-container .MuiPaper-root.MuiAccordion-root.MuiAccordion-rounded.MuiPaper-elevation1.MuiPaper-rounded {
	display: inline;
}

.query-accordian .MuiCollapse-wrapper .MuiCollapse-wrapperInner div div {
	height: auto;
}

.query-accordian .MuiTabs-scrollable span {
	background-color: transparent;
}

.query-accordian .MuiAccordionSummary-root{
	padding-right: 30px !important;
}

@media (max-width: 480px) {
	.search-input-box  {
		left: -5%;
	}
}

@media (max-width: 380px) {
	.search-input-box  {
		left: -6%;
	}
}

@media (max-width: 350px) {
	.search-input-box  {
		left: -7%;
	}
}

@media (max-width: 0px) {
	.search-input-box  {
		left: -8%;
	}
}

@media (min-width: 651px) {
	.search-input-box {
		max-width: 555px;
	}
}

@media (max-width: 576px) {
	.find-looking-content {
		padding: 20px 10px;
	}

	.find-looking-content .find-looking-text .find-looking-title {
		font-size: 20px;
	}
}

@media (min-width: 768px) {
	.find-looking-content .find-looking-text {
		width: 75%;
		float: left;
	}

	.find-looking-content .find-contact-btn {
		width: 20%;
		float: right;
	}

	.find-looking-content .find-looking-text .find-looking-title {
		font-size: 38px;
	}

	.find-looking-content .find-looking-text .find-looking-plain {
		font-size: 18px;
	}
}

@media (min-width: 910px) {
	.popular-links li {
		width: 50%;
    	float: left;
	}
}

@media (max-width: 850px) {
	#panel1a-header, .query-accordian .MuiTabs-vertical {
		/* max-width: 300px;
		min-width: 300px; */
	}

	.query-accordian .MuiTabs-flexContainerVertical button .MuiTab-wrapper {
		font-size: 14px;
	}

	.query-accordian .tab-content-container .set-up-organization-container .set-up-title {
		font-size: 20px;
		margin-bottom: 15px;
	}

	.query-accordian .tab-content-container .set-up-organization-container .set-up-text {
		font-size: 14px;
	}

	.query-accordian .tab-content-container .set-up-organization-container .on-page-title {
		font-size: 16px;
	}

	.query-accordian .tab-content-container .set-up-organization-container .on-page-lists ul li a {
		font-size: 13px;
	}

	.query-accordian .tab-content-container .set-up-organization-container .pro-tip .pro-tip-title {
		font-size: 16px;
	}

	.query-accordian .tab-content-container .set-up-organization-container .pro-tip .pro-tip-text {
		font-size: 12px;
	}
}

@media (max-width:900px) {
	.query-accordian #panel1a-header {
		padding-right: 0px !important;
	}

	.query-accordian #panel1a-content .MuiAccordionDetails-root {
		padding:0px !important;
	}
}

@media (max-width: 700px) {
	.query-accordian .makeStyles-root-5 {
		display: block;
	}

	#panel1a-header, .query-accordian .MuiTabs-vertical {
		/* max-width: 100%;
    	min-width: 100%; */
    	padding-left: 0;
    	border-right: 0px;
	}

	#panel1a-content .MuiAccordionDetails-root {
		padding-left: 0;
		padding-right: 0;
	}

	.query-accordian .tab-content-container {
		margin-top: 0px;
	}

	.query-accordian .tab-content-container .MuiBox-root {
		padding-left: 0;
	}

	.query-accordian .MuiIconButton-edgeEnd {
		margin:0;
	}

	.query-accordian .tab-content-container .set-up-organization-container .share-ein ul li {
		font-size: 14px;
	}
}