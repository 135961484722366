.deen-application {
	padding: 80px 0;
	text-align: center;
}

.deen-application .deen-app-title {
	color: #7266FB;
	font-family: 'Poppins', sans-serif;
	font-weight: bold;
	font-size: 18px;
	margin-top: 10px;
	margin-bottom: 10px;
}

.deen-application .deen-core-title {
	color: #4D4D4D;
	font-family: 'Poppins', sans-serif;
	font-size: 30px;
	font-weight: bold;
	text-align: center;
	margin-top: 10px;
	margin-bottom: 10px;
}

.deen-application .deen-plain-text {
	color: #818E94;
	font-family: 'Poppins', sans-serif;
	font-size: 18px;
	text-align: center;
	max-width: 70%;
    margin: 0 auto;
}

.deen-application .app-card-content {
	text-align: left;
}

.donation-t {
	margin: 6px 0 !important;
	color: #818E94 !important;
	font-size: 16px !important;
	font-family: 'Poppins', sans-serif !important;
}

.donor-t {
	margin: 20px 0 !important;
	color: #818E94 !important;
	font-size: 16px !important;
	font-family: 'Poppins', sans-serif !important;
}

.deen-application .learn-text-btn {
	color: #4d4d4d !important;
	font-weight: 700;
}

.integration-cards:hover .learn-text-btn span.MuiButton-label {
	color: #7266fb;
}

.integration-cards .coming-soon-text-btn {
	color: #818E94 !important;
}

.integration-cards:hover .coming-soon-text-btn.MuiButton-root:hover {
	background-color: transparent !important;
}

.app-card-content {
	cursor: pointer;
}

.app-card-content:hover .learn-text-btn {
	color: #7266FB !important;
}

.app-card-content .coming-soon-text-btn {
	color: #818E94 !important;
}

.deen-integration {
	padding: 80px 0;
	text-align: center;
	background-color: #F7F8FA;
}

.deen-integration .d-integration-title {
	color: #7266FB;
	font-family: 'Poppins', sans-serif;
	font-weight: bold;
	font-size: 18px;
	margin-top: 10px;
	margin-bottom: 10px;
}

.deen-integration .d-integration-plain {
	color: #4D4D4D;
	text-align: center;
	font-family: 'Poppins', sans-serif;
	font-weight: bold;
	font-size: 30px;
}

.deen-integration .d-int-plain-text {
	color: #818E94;
	font-family: 'Poppins', sans-serif;
	font-size: 18px;
	margin: 10px auto;
	max-width: 65%;
	line-height: 34px;
}

.deen-integration .custom-apps-integration span {
    padding: 0px 15px;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    color: #818E94;
    margin: 10px 5px;
    cursor: pointer;
    background-color: #fff;
    border-radius: 30px;
    border: 1px solid #E4E8EC;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.deen-integration .custom-apps-integration {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.deen-integration .custom-apps-integration .selected-integration-type, 
.deen-integration .custom-apps-integration span:hover {
	background-color: #7266FB;
	color: #fff;
	border: 1px solid #7266FB;
}

.deen-integration .deen-integration-cards {
	margin-top: 25px;
	text-align: left;
}

.deen-integration .deen-integration-cards .int-card-t {
	color: #4D4D4D;
	font-family: 'Poppins', sans-serif;
	font-weight: bold;
	font-size: 17px;
	letter-spacing: 0;
}


.deen-integration .deen-integration-cards .donor-t {
    font-size: 14px !important;
	line-height: 28px;
	height: 111px;
}

.integration-cards{
	padding: 14px 4px;
}

.integration-cards:hover {
	-webkit-box-shadow: 0px 0px 33px -9px rgba(0,0,0,0.17) !important;
	-moz-box-shadow: 0px 0px 33px -9px rgba(0,0,0,0.17) !important;
	box-shadow: 0px 0px 33px -9px rgba(0,0,0,0.17) !important;
	cursor: pointer;
}

.integration-cards hr{
	margin-top:10px;
	margin-bottom: 10px;
}

.orgLogostyle{
	max-width: 260px;
	width: 100%;
}

.logoSection{
	height: 90px;
}













@media (max-width: 767px) {
	.deen-application .deen-plain-text {
		max-width: 100%;
	}

	.deen-integration .d-int-plain-text {
		max-width: 100%;
		margin: 0 auto;
	}
}

@media (max-width: 576px) {
	.deen-integration .d-integration-plain {
		font-size: 24px;
	}

	.deen-integration .d-int-plain-text, .deen-application .deen-plain-text {
		font-size: 16px;
	}
}