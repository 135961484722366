.view-label div {
	display: initial;
}

.letusknow-modal {
	position: relative;
	width: 768px;
	/* height: 520px; */
	font-family: 'Poppins', sans-serif;

}

.letusknow-modal .close_icon {
    position: absolute;
    right: 30px;
    top: 23px;
    color: #818E94;
    cursor: pointer;
    font-size: 24px;
}

.letusknow-modal .title {
	text-align: center;
	font-family: 'Poppins', sans-serif;
	font-size: 30px;
	color: #4D4D4D;
	font-weight: bold;
	margin-top: 60px;
	margin-bottom: 30px;
}

@media (max-width: 576px) {
	.letusknow-modal .title {
		font-size: 22px;	
	}
}


/* .letusknow-modal .message input {
	height: 100px;
} */

.letusknow-modal .word-count {
	position: relative;
    font-size: 0.75rem;
	text-align: right;
	font-family: 'Poppins', sans-serif;
	color: rgba(0, 0, 0, 0.54);
    top: -25px;
    font-size: 0.70rem;
}

.letusknow-modal .captcha {
	display: flex;
	justify-content: center;
}

.letusknow-modal .send-message {
	background-color: #7266FB;
    color: #fff;
    padding: 7px 20px;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    margin: 15px auto;
    text-align: center;
    border-radius: 50px;
	display: block;
	height: 46px;
}

.letusknow-modal .send-message:hover {
	background-color: #7266FB;
}




.integrations-modal-t {
	width: 1140px;
	height: auto;
}

.integrations-modal-t .title-a {
	text-align: center;
	color: #4D4D4D;
	font-family: 'Poppins', sans-serif;
	font-size: 18px;
	font-weight: bold;
	margin: 10px 0;
}

.integrations-modal-t .accept-t {
	color: #818E94;
	font-family: 'Poppins', sans-serif;
	font-size: 12px;
	text-align: center;
	margin: 10px 0;
}

.integration-detail-listing-modal {
	background-color: #fff;
    text-align: center;
    width: 100%;
    position: relative;
    max-width: 1140px;
    height: 630px !important;
    overflow-y: auto;
}

.integration-compatible {
	color: #4D4D4D;
	font-family: 'Poppins', sans-serif;
	font-size: 14px;
	font-weight: 600;
}

.header-xt.check-td {
	width: 1px;
}