.FooterMainBoxBottomGrey {
    background: #FBFAFD;
    padding: 15px;
    border-top: #ebeaed 1px solid;
}

.FooterMainBoxBottomWhite{
    background: #fff;
    padding: 15px;
    border-bottom: #7266FB 5px solid;
}

.termText {
    text-align: left;
    font-size: 12px;
    text-transform: uppercase;
    text-decoration: none;
    color: #828282;
}


.termlink { 
    color: #828282 ;
}

.termTextRight {
    text-align: right;
}



.heartIcon {
    color: #828282;
    font-size: 17px;
    vertical-align: middle;
}

.dclogoftr {
    width: 146px;
    margin-left: 5px;
}
.pweredText {
    vertical-align: middle;
    display: flex;
    justify-content: center;
    color: #828282;
    align-items: center;
    font-size: 12px;
}
.visitUs {
    text-align: right;
    color: #828282;
    text-decoration: none;
}

.textislam {
    color: #828282;
}

.facebookImg {
    width: 26px;
    position: relative;
    top: 8px;
}

.social_icon {
    width: 41px;
    height: 41px;
    background: #fff;
    border: #666 1px solid;
    display: inline-block;
    border-radius: 100%;
    margin: 0 8px;
}

.facebook_icon {
    width: 41px;
    height: 41px;
    display: inline-block;
    border-radius: 100%;
    background: #666;
    margin: 0 8px;
}

.footerSocial {
    padding-top: 20px;
}

@media (max-width: 960px) {
    .termText{
        text-align: center;
    }

    .termTextRight {
        text-align: center;
    
    }
}
