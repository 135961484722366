.terms-policy-tab ul {
	background-color: #F7F8FA;
	font-family: 'Poppins', sans-serif;
	justify-content: center;
}

.terms-policy-tab ul li.active {
	background-color: #fff;
	color: #7266FB;
}

.terms-policy-tab ul li {
	color: #4D4D4D;
	font-size: 14px;
	padding: 20px 50px;
	font-weight: 500;
}

.terms-policy-tab .jDNNKh.bg-success {
	background-color: #7266FB;
}

 .terms-content-container {
	padding: 60px 15px;
}

 .terms-content-container .main-heading {
	color: #444444;
	font-family: 'Poppins', sans-serif;
	font-size: 25px;
	font-weight: 900;
	margin-bottom: 5px;
	line-height: 1;
}

 .terms-content-container .dated-label {
	color: #444444;
	font-family: 'Poppins', sans-serif;
	font-size: 14px;
}

 .terms-content-container .text-label {
	padding: 50px 0;
	color: #828282;
	font-size: 14px;
	font-family: 'Poppins', sans-serif;
	line-height: 28px;
}

 .terms-content-container .semi-label {
	color: #444444;
	font-size: 25px;
	font-weight: 700;
	font-family: 'Poppins', sans-serif;
	margin-bottom: 20px;
}

 .terms-content-container .plain-text {
	color: #828282;
	font-family: 'Poppins', sans-serif;
	font-size: 14px;
	line-height: 28px;
}

 .terms-content-container .plain-text .plain-text-li {
	background: none;
	padding-left: 25px;
}

 .terms-content-container .plain-text .plain-text-li li {
	padding: 5px 0;
	color: #828282;
	font-family: 'Poppins', sans-serif;
	font-size: 14px;
	list-style: none;
	font-weight: 400;
}

.plain-text-li.bullets-li li {
	list-style: inherit !important;
}

.faq-section {
	background-color: #F7F8FA;
	padding: 80px 0;
	font-family: 'Poppins', sans-serif;
}

.faq-section .MuiAccordion-root.Mui-expanded {
	margin: 0 !important;
}

.faq-section .MuiPaper-root {
    background-color: transparent;
}

.faq-section .collapse-heading {
	color: #4D4D4D;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    cursor: pointer;
}

.MuiPaper-root.MuiAccordion-root.Mui-expanded.MuiAccordion-rounded.MuiPaper-elevation1.MuiPaper-rounded {
	border-top:1px solid #d7d9da !important;
	border-bottom:1px solid #d7d9da !important;
}
.faq-section .MuiAccordionSummary-root, .faq-section .MuiAccordionDetails-root {
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.MuiPaper-root.MuiAccordion-root.MuiAccordion-rounded.MuiPaper-elevation1.MuiPaper-rounded {
	border: none;
}


.faq-section .faq-title {
	color: #4D4D4D;
	font-family: 'Poppins', sans-serif;
	font-weight: bold;
	font-size: 30px;
	text-align: center;
	margin-bottom: 30px;
}

.Collapsible{
	padding-top: 14px;
	padding-bottom: 14px;
	border-top: 1px solid #d7d9da;
}

.faq-section .Collapsible span {
	color: #4D4D4D;
	font-family: 'Poppins', sans-serif;
	font-weight: 500;
	font-size: 14px;
	display: flex;
    cursor: pointer;
}

.faq-section .Collapsible div {
	font-family: 'Poppins', sans-serif;
	font-size: 12px;
	color: #828282;
}


.Collapsible__trigger.is-open{
	padding-bottom: 22px;
}
.who-is-deen {
	margin-top: 35px !important;
}

.custom-mb {
	margin-bottom: 20px !important;
}

.custom-mt {
	margin-top: 25px !important;
}

.terms-policy-tabs .MuiGrid-spacing-xs-3 {
	width: calc(100% + 12px) !important;
}

.address_text{
	font-size: 16px !important;
}

.cuIYFB {
	background-color: #7266fb !important;
  }
  
  .jDNNKh.bg-success {
	background-color: #7266FB !important;
  }
  
  .bg-success {
	background-color: #7266FB !important;
  }
  

@media (max-width: 576px) {
	.terms-policy-tab ul {
		display: block;
	}
	 ul {
		display: block;
	}

	 ul li {
		text-align: center;
	}

	 .jDNNKh.bg-success {
		display: none;
	}

	.plain-text-li {
padding-left: 0;
	}

	.plain-text-li li {
		text-align: left;
			}
}

@media (max-width: 767px) {
	 .terms-content-container {
		padding: 60px 15px;
	}

	.terms-policy-tab button{
		max-width: initial;
	}

	.hardware-details-modal{
		margin-left:0px;
	}

	.carousel .thumb.selected, .carousel .thumb {
		width: auto !important;
		padding: 0 !important;
		margin-bottom: 7px;
	}

	.integrations_table tr td .event-dfx {
		margin-right: 10px;
	}

	.integration-header .first_td {
		padding-left: 0;
	}


	.create-your-own {
		margin-bottom: 4px;
		background-color: #F7F8FA;
	}

	.faq-section {
		padding: 30px 0;
	}





}

.MuiTabs-flexContainer {
	background-color: #F7F8FA;
    font-family: 'Poppins', sans-serif;
    justify-content: center;
}

.terms-policy-tabs .Mui-selected, .terms-policy-tab button.Mui-selected {
	background-color: #fff;
    color: #7266FB;
}

.terms-policy-tab button {
	color: #4d4d4d;
	width: 100%;
}

.privacy-plan-tabs .terms-policy-tab .MuiTabs-flexContainer {
	flex-wrap: wrap;
}

.privacy-plan-tabs .MuiBox-root {
	padding: 0 !important;
}

.privacy-plan-tabs .terms-policy-tab {
	border-bottom: 1px solid #E8E9E9;
}

.privacy-plan-tabs .terms-policy-tab .MuiTabs-indicator {
	display: none;
}

.terms-policy-tab button.Mui-selected {
	border-bottom: 2px solid #7266FB;
}