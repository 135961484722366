@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.CareersTopBanner{

}

.CareersTopBanner {
    max-height: 360px;
    overflow: hidden;
}

.CareersTopBanner img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.CareersDescription{
    max-width: 750px;
    margin: 0 auto;
    padding-top: 20px;
}

.CareersDescription h1{
    font-size: 34px;
    color: #4D4D4D;
    line-height: 40px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
}

.CareersDescription p{
    font-size: 20px;
    color: #2D2D2D;
    line-height: 32px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}

.CareersPositionSection h1{
    color: #fff;
    text-align: center;
    font-size: 30px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    line-height: 40px;

}

.CareersPositionCard{
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    position: relative;

}

.CareersPositionCard h2{
    font-size: 34px;
    color: #2D2D2D;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    line-height: 40px;
    margin: 0;
    
}

.CareersPositionCardFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;
}

.CareersPositionCardFooter .DetailsIButton {
    position: absolute;
    display: flex;
    width: 56px;
    height: 56px;
    align-items: center;
    justify-content: center;
    right: 0;
    bottom: 0;
}

.CareersPositionCardFooter  p{
    margin: 0;
    font-size: 14px;
    line-height: 24px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;  
    color:#4D4D4D; 
    
}

.colorGrey{
    color: #818E94 !important;
}


.CareersPositionCardFooter .DetailsIButton svg {
    width: 23px;
    position: absolute;
    color: #fff;
    left: 22px;
}
.CareersPositionCardFooter .DetailsIButton svg:first-child {
    left: 16px;
}
.greyBtn{
    background:#D3D8DA;
}

.greenBtn{
    background:#05CE78;
}


.CareerListSection{}

.CareerListSection h1{
    color: #2D2D2D;
    font-size: 24px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    text-align: left;
    line-height: 24px;
}

.CareerListSection p{
    color: #4D4D4D;
    font-size: 20px;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    text-align: left;
    line-height: 32px;
}

.CareerListCard{
    text-align: left;
    padding-top: 30px;
}

.CareerListSection img{
    width: 50px;
}

.PageTitleViewBtn .applyBtn {
    background: #fff;
    color: #7266FB !important;
    border-radius: 100px;
    padding: 0px 20px;
    text-align: center;
    justify-content: center;
    margin-top: 20px !important;
    display: inline-block;
}

.CareersApplyTopBanner{
    max-width: 750px;
    margin: 0 auto;
}

.CareersApplyTopBanner img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.CareersDescription .ListPointsSection {
    padding-top: 2px;
}

.CareersDescription .ListPointsSection h2{
    color: #2D2D2D;
    font-size: 24px;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    text-align: left;
    line-height: 24px;
}

.CareersDescription .ListPointsSection ul{
   margin-left: 46px;
   padding: 0;
}

.CareersDescription .ListPointsSection ul li{
    color: #2D2D2D;
    font-size: 20px;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    text-align: left;
    line-height: 32px;
    margin-bottom: 24px;
 }

 .ApplyFormContainer{
     background: #F7F8FA;
     padding: 40px;
     max-width: 750px;
     margin: 0 auto;
 }

 .ApplyFormTitleSection{
     display: flex;
     justify-content: space-between;
     align-items: center;
     border-bottom: #E4E8EC 1px solid;
     padding-bottom: 32px;
     margin-bottom: 22px;
 }

 .ApplyFormTitleSection h1{
    color: #2D2D2D;
    font-size: 24px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    line-height: 32px;
    margin: 0;
}

.ApplyFormTitleSection p{
    color: #2D2D2D;
    font-size: 12px;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    line-height: 16px;
    margin: 0;
}

.ApplyFormTitleSection p span{
    color: #C44058;
}

.ApplyFormContainer .PhoneInputContainer .PhoneInputfieldContainer .react-tel-input .form-control {
    position: relative;
    font-size: 14px;
    letter-spacing: .01rem;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 60px !important;
    margin-left: 0;
    background: #FFFFFF;
    border: 1px solid #E4E8EC !important;
    line-height: 25px;
    height: 50px !important;
    outline: none;
    min-height: 55px;
    border-radius: 4px !important;
}

.ApplyFormContainer .PhoneInputContainer  .react-tel-input .selected-flag {
    outline: none;
    position: relative;
    width: 51px;
    height: 100%;
    padding: 0 0 0 15px !important;
    border-radius: 3px 0 0 3px;
    border-right: #E4E8EC 1px solid;
}

.ApplyFormContainer .PhoneInputContainer .react-tel-input .flag-dropdown{
    border-bottom: 1px solid #E4E8EC !important;
}

.PhoneInputfieldContainer{
    position: relative;
    top: -3px;
}

.MediaUploaderContaierDC .second-row{
    margin: 0 !important;
}

.MediaUploaderContaierDC .logo-field{
    background: #fff;
}

.MediaUploaderContaierDC .org-accordian .cloud-icon {
    color: #2A8853;
}

.MediaUploaderContaierDC .org-accordian .cloud-icon svg {
    fill: #2A8853;
    padding: 4px;
    border-radius: 50%;
    margin-right: 8px;
}

.MediaUploaderContaierDC .second-row .photo-icon{
    background:#2A8853;
}

.DropdownSelectContaierDC .MuiInput-root {
    position: relative;
    background: #fff;
}

.DropdownSelectContaierDC .MuiInput-underline:after {
    border-bottom: none !important;
}

.CheckBoxContaierDC .MuiFormControlLabel-root {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    margin-left: -11px;
    margin-right: 16px;
    vertical-align: baseline;
    align-items: baseline;
}
.CheckBoxContaierDC .MuiFormControlLabel-root p{
    font-size: 14px;
    color: #4D4D4D;
    position: relative;
    top: -5px;
    font-family: 'Poppins', sans-serif;
}
.CheckBoxContaierDC .MuiFormControlLabel-root p a{
    font-size: 14px;
    color: #2490F9;
    font-family: 'Poppins', sans-serif;
}
.ApplyFormContainer .MuiButton-containedSizeLarge{
    width: 100%;
}

.MuiTabs-flexContainer .MuiTab-root {
    width: auto;
    padding: 0 30px;
}

html {
    scroll-behavior: smooth;
  }

  .ApplyFormContainer .MuiButtonBase-root{
    border-radius: 4px !important;
  }
