@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.GreySection {
    background-color: #F7F8FA;
    padding: 40px 0;
}

.WhiteSection {
    background-color: #fff;
    padding: 40px 0;
}

.Purpal {
    background-color: #39337E;
    padding: 40px 0;
}

.PressAboutSection{
    max-width: 900px;
    margin: 0 auto;
}

.PressAboutSection h1{
    color: #4D4D4D;
    font-size: 30px;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    line-height: 40px;
}

.PressAboutSection h2{
    color: #7266FB;
    font-size: 18px;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    text-align: center;
}

.PressAboutSection p{
    color: #2D2D2D;
    font-size: 20px;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    line-height: 32px;
}

.PressAboutListSection{}

.PressAboutListSection h1{
    color: #2D2D2D;
    font-size: 24px;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    line-height: 24px;
}

.PressAboutListSection p{
    color: #2D2D2D;
    font-size: 20px;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    line-height: 32px;
}

.PressAboutListCard{
    text-align: center;
    padding-top: 30px;
}

.PressAboutListCard img{
    width: 50px;
}

.PressNewstListCard{
    background: #fff;
    border-bottom: #0000000F 1px solid;
    padding:0px 20px;
    display: flex;
    cursor: pointer;
    transition: 0.3s;
    justify-content: space-between;
    align-items: center;
    height: 120px;
}

.PressNewstListCard:hover{
    box-shadow: 0px 9px 17px #0000000F;
    z-index: 2;
    position: relative;
}


.PressNewstListCard h1{
    color: #39337E;
    font-size: 20px;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    text-align: left;
    line-height: 28px;
    margin: 0;
}

.PressNewstListCard p{
    color: #2D2D2D;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    text-align: left;
    line-height:24px;
    margin: 0;
}

.PressNewstListCard p span{
    color: #818E94 !important;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    text-align: left;
    line-height:24px;
    margin: 0;
    padding-top: 10px;
}

.PressNewstListCard .PressNewstListCardArrow {
    width: 40px;
    height: 40px;
    border: #D3D8DA 1px solid;
    border-radius: 100px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.PressNewstListCard .PressNewstListCardArrow svg{
    font-size: 15px;
    color: #2D2D2D;
}

.DeenConnectLogosContainer{
    background: #fff;
    max-width: 360px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 30px;
}

.DeenConnectLogosContainer.BlueBG{
    background:#39337E;
}

.DeenConnectLogosContainer span {
    background: #F7F8FA;
    height: 50px;
    display: block;
    width: 100%;
}


.DeenConnectLogosContainer img{
   object-fit: contain;
   width: 100%;
   height: 50px;
}

.DownloadLogoButton{
    text-align: center;
    margin-top: 30px;
}

.DownloadLogoButton a{
    background: #7266FB;
    border-radius: 100px;
    color: #fff;
    text-transform: uppercase;
    font: 14px;
    display: inline-block;
    padding: 8px 24px;
}