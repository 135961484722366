.how-it-work-section {
	background-color: #7266FB;
	padding: 70px 0;
	text-align: center;
}

.how-it-work-section .how-title {
	color: #FFFFFF;
	font-family: 'Poppins', sans-serif;
	font-size: 48px;
	font-weight: bold;
	line-height: 62px;
}

.how-it-work-section .how-plain {
	color: #FFFFFF;
	font-family: 'Poppins', sans-serif;
	font-size: 18px;
	max-width: 80%;
	margin: 20px auto 0;
	line-height: 34px;
}

.how-it-work-section .PageTitleViewBtn a{
	color: #FFFFFF;
	font-family: 'Poppins', sans-serif;
	font-size: 14px;
	margin: 20px auto 0;
	line-height: 34px;
	font-weight: 600;
	cursor: pointer;
}

.how-it-work-section .PageTitleViewBtn a svg{
	color: #FFFFFF;
	font-size: 14px;
	margin-left: 5px;
	position: relative;
	top: 2px;
}

.textCenter{
	text-align: center;
}

.work-process-section {
	background-color: #F7F8FA;
	padding: 80px 0;
	font-family: 'Poppins', sans-serif;
	text-align: center;
}

.work-process-section .work-title {
	color: #7266FB;
	font-family: 'Poppins', sans-serif;
	font-weight: bold;
	font-size: 18px;
	margin-top: 10px;
	margin-bottom: 10px;
	text-align: center;
}

.work-process-section .work-plain-title {
	color: #4D4D4D;
	font-size: 30px;
	font-family: 'Poppins', sans-serif;
	font-weight: bold;
	margin-top: 10px;
	margin-bottom: 10px;
	line-height: 1.4;
}

.work-process-section .work-plain-text {
	color: #818E94;
	text-align: center;
	font-family: 'Poppins', sans-serif;
	max-width: 62%;
	margin: 0 auto;
	font-size: 18px;
}

.ready-start-section {
	background-color: #7266FB;
	padding: 80px 0;
	color: #fff;
	font-family: 'Poppins', sans-serif;
}

.ready-start-section .ready-title {
	font-size: 18px;
	font-family: 'Poppins', sans-serif;
	font-weight: bold;
	margin: 10px auto;
	text-transform: uppercase;
	text-align: center;
}

.ready-start-section .ready-heading {
	font-size: 30px;
	font-family: 'Poppins', sans-serif;
	font-weight: bold;
	margin: 10px auto;
	max-width: 85%;
	text-align: center;
	line-height: 40px;
}

.ready-start-section .ready-plain-text {
	font-size: 18px;
	font-family: 'Poppins', sans-serif;
	margin: 10px auto;
	text-align: center;
    max-width: 75%;
}

.ready-start-section .about-get-start-btn {
	margin: 0 auto;
}
.ready-start-section .get-start-btn {
    background-color: #fff !important;
    color: #7266FB !important;
    padding: 11px 20px !important;
    font-family: 'Poppins', sans-serif;
    font-size: 14px !important;
    margin: 30px auto;
    border-radius: 100px;
    letter-spacing: 1.6px;
    width: 148px;
}
.work-process-section .counter-no {
    background-color: #f0eafe;
    padding: 0;
    border-radius: 100px;
    width: 56px !important;
    margin: 20px auto !important;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.work-process-section .counter-no span {
	color: #7266FB;
	font-weight: bold;
	font-family: 'Poppins', sans-serif;
	font-size: 26px;
}

.work-process-section .card-title-t {
	font-size: 18px;
	font-family: 'Poppins', sans-serif;
	font-weight: bold;
	color: #4D4D4D;
}

.work-process-section .card-plain-text {
    color: #818E94;
    line-height: 30px;
    font-size: 16px;
    margin: 20px auto;
}

@media (min-width:1280px) {
	.work-process-section .work-process-card-section {
		background-image: url("../assets/Arrow-Line.png");
		background-repeat: no-repeat;
		background-position: center 0px;
		background-size: 100% 100%;
		padding-top: 30px;
		padding-bottom: 30px;
	}
}

@media(max-width:1279px) {
	.work-process-card-section {
		margin-top:25px;
	}
}





.WORK_PROCESS_CARD{
	border: #E8E9E9 1px solid;
	height: 274px;
}


.feature-section {
	padding: 80px 0 150px;
	text-align: center;
}

.feature-section .feature-title {
	color: #7266FB;
	font-family: 'Poppins', sans-serif;
	font-weight: bold;
	font-size: 18px;
	margin: 10px auto;
}

.feature-section .feature-main-title {
	color: #4D4D4D;
	font-family: 'Poppins', sans-serif;
	font-weight: bold;
	font-size: 30px;
	margin: 10px auto;
}

.feature-section .feature-content {
	text-align: left;
}

.feature-section .feature-content-title {
	color: #4D4D4D;
	font-family: 'Poppins', sans-serif;
	font-weight: bold;
	font-size: 30px;
	margin: 10px auto;
}

.feature-section .feature-content-plain-t {
	color: #818E94;
	font-family: 'Poppins', sans-serif;
	font-size: 18px;
	margin: 15px 0;
	padding-right: 30px;
	line-height: 34px;
}

.feature-section .feature-listing {
	margin: 10px 0;
	color: #4D4D4D;
	font-family: 'Poppins', sans-serif;
	font-weight: bold;
	font-size: 16px;
	display: flex;
    align-items: baseline;
}

.feature-section .feature-listing img {
	position: relative;
	top: 7px;
	margin-right: 5px;
}

.feature-section .feature-content {
	margin-top: 30px;
}

.home-contact-form {
	background-color: #F7F8FA;
	padding: 80px 0;
}

.home-contact-form .form-container {
	max-width: 765px;
	margin: 0 auto;
}

.home-contact-form .form-container .contact-title {
	color: #7266FB;
	font-family: 'Poppins', sans-serif;
	font-weight: bold;
	font-size: 18px;
	text-align: center;
}

.home-contact-form .form-container .contact-plain-title {
	color: #4D4D4D;
	font-family: 'Poppins', sans-serif;
	font-weight: bold;
	font-size: 30px;
	text-align: center;
	margin-top: 10px;
}

.home-contact-form .full-name-input, .home-contact-form .email-input {
	width: 100% !important;
}

/* .home-contact-form .email-input-error {
	border-bottom: 1px solid red !important;
} */


.home-contact-form .captcha {
	height: 60px;
    max-width: 300px;
    text-align: center;
    color: #D3D8DA;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    background-color: #fff;
    margin: 20px auto;
	line-height: 60px;
	margin-top: 50px;
}

.home-contact-form .send-message {
	background-color: #7266FB;
    color: #fff;
    padding: 7px 20px;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    margin: 35px auto;
    text-align: center;
    border-radius: 50px;
	display: block;
	height: 46px;
}

.home-contact-form .send-message:hover {
	background-color: #7266FB;
}

.about-tab-section .button_group {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
}

.about-tab-section .MuiTab-textColorInherit {
	color: #4d4d4d;
}

/* .about-tab-section .MuiTab-textColorInherit:hover, .about-tab-section .MuiTab-textColorInherit:focus, .about-tab-section .MuiTab-textColorInherit:active {
	color: #7266FB;
	opacity: 1;
} */

.violet .tab-content .tab-information p {
	margin-bottom: 30px !important;
}

.violet .tab-content .tab-information button {
	margin-bottom: 80px;
}


.MuiInput-underline:before{
	border-bottom: 1px solid #D3D8DA !important;
}
.MuiInput-underline:after {
    border-bottom: 2px solid #7266FB !important;
}

.Mui-error .MuiInput-underline:after {
    border-bottom: 2px solid #f44336 !important;
}


.field_group{
	position: relative;
}

.helper_text {
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    color: #B6BFC4;
    letter-spacing: 0.4px;
    display: inline-block;
    position: absolute;
    bottom: -21px;
    left: 12px;
}

.cat_limit {
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    color: #B6BFC4;
    letter-spacing: 0.4px;
    position: absolute;
    right: 13px;
}

.grid_container{}

.col_left {
    float: left;
    max-width: 58%;
    flex-basis: 58%;
    padding: 11px;
}

.col_right{
	float: right;
	max-width: 58%;
    flex-basis: 58%;
    padding: 11px;
}

.d-int-plain-text a{
	font-size: 18px;
}

.feature-illustration-img{
	max-width:448px;
	width: 80%;
}

.deen-integration-cards .MuiCardContent-root {
    padding-bottom: 12px !important;
}

@media (max-width: 1100px) {
	.feature-illustration-img {
		width: 100%;
	}
}

@media (max-width: 767px) {
	.about-tab-section .MuiTabs-flexContainer {
		flex-wrap: wrap;
	}
	.how-it-work-section .how-title {
		font-size: 40px;
	}

	.tab-content .tab-information, .tab-content .tab-plain-title, .how-it-work-section .how-plain, .ready-start-section .ready-heading, .work-process-section .work-plain-text, .ready-start-section .ready-plain-text {
		max-width: 100% !important;
	}

	.about-tab-section .MuiBox-root.MuiBox-root-7 {
		padding: 0 !important;
	}

	.home-contact-form .full-name-input, .home-contact-form .email-input {
		width: 100% !important;
	}

	.home-contact-form .email-input {
		margin-left: 0px !important;
	}

	.feature-section .feature-content-plain-t {
		padding-right: 0 !important;
	}

	.feature-section .feature-content {
		margin-top: 0px;
	}
}

@media (max-width: 576px) {


	.feature-section .feature-content-title, .home-contact-form .form-container .contact-plain-title, .feature-section .feature-main-title, .ready-start-section .ready-heading, .tab-content .tab-plain-title, .work-process-section .work-plain-title {
		font-size: 20px;
	}

	.how-it-work-section .how-plain, .ready-start-section .ready-plain-text, .tab-content .tab-plain-text {
		font-size: 14px;
	}

	.feature-section .feature-listing {
		font-size: 12px;
	}

	.feature-section .feature-listing img {
		top: 10px;
	}

	.ready-start-section .ready-heading {
		font-size: 18px;
		line-height: 30px;
	}


}

.button_group button {
	opacity: 0.7;
	padding: 6px 15px;
    overflow: hidden;
    position: relative;
    font-size: 0.875rem;
    max-width: 264px;
    min-width: 72px;
    box-sizing: border-box;
    min-height: 48px;
    text-align: center;
    flex-shrink: 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.75;
    white-space: normal;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
}
.button_group button:hover {
	
}


.button_group button span {
	width: 100%;
    display: inline-flex;
    align-items: center;
    flex-direction: column;
	justify-content: center;
	
}

.about-tab-section .MuiTab-textColorInherit:hover {
    color: #7266FB !important;
	opacity: 1;
}

.about-tab-section .MuiTab-textColorInherit.Mui-selected{
    color: #7266FB !important;
	opacity: 1;
}

.about-tab-section .MuiButtonBase-root.Mui-selected {
	border-bottom: none !important;
}

@media (max-width: 559px) {

	.col_left {
		float: none;
		max-width: 100%;
		flex-basis: 100%;
		padding: 11px;
	}
	
	.col_right{
		float: none;
		max-width: 100%;
		flex-basis: 100%;
		padding: 11px;
	}

}

.green .MuiAlert-filledSuccess {
    color: #fff;
    font-weight: 500;
    background-color: #05CE78;
    height: 80px;
    display: flex;
    align-items: center;
	font-size: 16px;
}

.EmailAlert{
	position: fixed;
	top: 0;
	width: 100%;
	left: 0;
	background: #05CE78;
	z-index: 9999;
	padding-top: 10px;
	padding-bottom: 10px;
}

.EmailAlertContainer{
	max-width: 1170px;
	margin: 0 auto;
}

.EmailAlert .EmailAlertContainer .MuiAlert-standardSuccess{
	background-color: transparent !important;
}

.EmailAlert .EmailAlertContainer .MuiAlert-standardSuccess .MuiAlert-message{
	color: #fff;
}

.EmailAlert .EmailAlertContainer  svg{
	color: #fff;
}

.monthly-cost-container .CardSlectedListBody{
	padding: 30px !important;
	margin-top: 3px;
}