.stepertitle1 {
	color: #4D4D4D;
	font-family: 'Poppins', sans-serif;
	font-size: 24px !important;
	margin: 20px 0 !important;
}

.card-step {
	padding: 30px !important;
	border-radius: 5px;
	border: 1px solid #E4E8EC;
	position: relative;
}

.card-step:hover {
	box-shadow: 0px 1px 7px 1px #E4E8EC;
	cursor: pointer;
}

.card-step .card-step-logo img {
    width: 48px;
    box-shadow: 0px 1px 7px 1px #E4E8EC;
    padding: 5px;
	border-radius: 10px;
	margin-bottom:10px;
}
.selected-appointment.card-step {
	position: relative;
	border: 1px solid #7266FB;
}

.selected-appointment.card-step .selective-appoint {
	position: absolute;
	right: 5px;
	top: 5px;
}

.card-step .card-step-text .card-step-title {
	color: #4D4D4D;
	font-family: 'Poppins', sans-serif;
	font-weight: bold;
	font-size: 20px;
}

.card-step .card-step-text .card-step-plain {
	color: #818E94;
	font-family: 'Poppins', sans-serif;
	font-size: 14px;
	line-height: 1.8;
}

.cancel-next-btn {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.card-step {
	margin-bottom: 15px !important;
}


@media (min-width: 600px) {
	.card-step {
    	flex-grow: 0;
    	max-width: 49% !important;
	    flex-basis: 49% !important;
	}
}

@media (min-width: 960px) {
	.card-step {
    	flex-grow: 0;
    	max-width: 32.333333% !important;
	    flex-basis: 32.333333% !important;
	}
}