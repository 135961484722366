@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.SubscriptionMainContiner{
    background:#F7F8FA;
    padding: 20px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.SubscriptionFieldContainer{
    display: flex;
    flex: 1;
  }
  
  .SubscriptionFieldContainer .SubscriptionField{
    width: 100%;
  }
  .SubscriptionFieldContainer .SubscriptionField .input_container .MuiInputBase-input, .input_label{
    font-size: 16px;
  }

  .SubscriptionFieldContainer .SubscriptionField .input_container .MuiOutlinedInput-input {
    height: 48px;
    padding: 0 16px;
    width: 100%;
    background: #fff;
    border: #ccc 1px solid;
    border-radius: 0px;
}

.SubscriptionFieldContainer .SubscriptionField .MuiOutlinedInput-root:hover{
    outline: none;
    border: none;
}

.SubscriptionFieldContainer .SubscriptionField .MuiOutlinedInput-notchedOutline{
    display: none;
}
  
  .SubscriptionFieldContainer .SubscriptionButton .MuiButtonBase-root {
    border-radius:  0 !important;
    height: 50px !important;
    margin: 0 !important;
    background-color:#7266FB;
  }

.SubscriptionTextContainer {
    display: flex;
    align-items: center;
    flex: 1;
}

  .SubscriptionMainContiner .SubscriptionTextContainer .SubscriptionEmailIcon{
    width:50px;
    height: 50px;
    margin-right: 20px;
  }

  .SubscriptionTextCol h2{
      font-size: 20px;
      color:#2D2D2D;
      line-height: 24px;
      margin: 0;
  }

  .SubscriptionTextCol p{
    font-size: 14px;
    color:#2D2D2D;
    line-height: 20px;
    margin: 0;
    padding-top: 5px;
}

