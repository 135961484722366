.privacy-plan-tabs .MuiPaper-elevation1 {
	border: #E4E8EC 1px solid;
}

.privacy-plan-tabs .pricing-container {
	margin-top: 55px;
	margin-bottom: 100px;
}

.privacy-plan-tabs .free.pricing-package .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
	color: #7266FB !important;
}

.privacy-plan-tabs .free.pricing-package .MuiSwitch-colorPrimary.Mui-checked {
	color: #7266FB !important;
}

.see-df-text-top-bar {
	color: #818E94;
	font-size: 12px;
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
}

.see-feature-top-bar.img {
	margin-bottom: 0px !important;
}

.see-feature-top-bar.img img {
    width: auto;
    height: 40px;
    object-fit: contain;
}

.see-df-text-top-bar {
	font-size: 12px !important;
	height: 30px;
}

.privacy-plan-tabs .pricing-container .blue-text {
	color: #7266FB;
	font-size: 14px;
	font-family: 'Poppins', sans-serif;
	font-weight: 500;
}

.privacy-plan-tabs .pricing-container .gray-text {
	color: #4D4D4D;
	font-size: 14px;
	font-family: 'Poppins', sans-serif;
	font-weight: 500;
}

.privacy-plan-tabs .pricing-container .green-text {
	color: #05CE78;
	font-size: 14px;
	font-family: 'Poppins', sans-serif;
	font-style: italic;
}

.privacy-plan-tabs .pricing-container .pricing-head {
	text-align: center;
	background-color: #f7f8fa;
	border-bottom: 1px solid #E4E8EC;
	padding: 20px 15px;
}

.privacy-plan-tabs .pricing-head .title-t {
	color: #4D4D4D;
	font-family: 'Poppins', sans-serif;
	font-weight: 600;
	font-size: 18px;
}

.privacy-plan-tabs .price-t {
	color: #4D4D4D;
	font-family: 'Poppins', sans-serif;
	font-size: 26px;
	font-weight: 500;
}

.privacy-plan-tabs .pricing-m {
	color: #4D4D4D;
	font-family: 'Poppins', sans-serif;
	font-size: 18px;
}

.privacy-plan-tabs .billed-m {
	color: #818E94;
	font-size: 14px;
	font-family: 'Poppins', sans-serif;
}

.privacy-plan-tabs .gsf-btn, .privacy-plan-tabs .gsf-btn:hover {
	background-color: #7266FB;
	color: #fff;
	font-size: 14px;
	font-family: 'Poppins', sans-serif;
	border-radius: 35px;
	padding: 5px 20px;
	border: none;
	margin-top: 15px;
	font-weight: 600;
}

.privacy-plan-tabs .pricing-body-t {
	color: #2D2D2D;
	font-family: 'Poppins', sans-serif;
	font-size: 14px;
	font-weight: 600;
}

.plan-card .MuiCardContent-root {
	padding: 0;
}

.plan-card .pricing-body {
	padding: 20px 15px;
	min-height: 400px;
}

.plan-card .pricing-features {
	display: flex;
	margin:20px 0;
	color: #4D4D4D;
	font-family: 'Poppins', sans-serif;
	font-size: 14px;
}

.plan-card .pricing-features img {
	width: 20px;
	height: 20px;
}

.plan-card .pricing-features p {
	padding-left: 8px;
	font-size: 14px;
}

.pricing-features .feature-t {
	text-transform: capitalize;
}

.plan-card .feature-btn {
	text-align: center;
	padding: 20px 15px 0;
}

.plan-card .feature-btn button {
	background: #f7f8fa;
    width: 100%;
    color: #7266FB;
    border-radius: 25px;
}

.privacy-plan-tabs .most-popular {
	background-color: #7266FB;
	color: #fff;
	font-family: 'Poppins', sans-serif;
	font-size: 14px;
	font-weight: 500;
	padding: 0px !important;
    border-radius: 4px 4px 0px 0px;
    text-align: center;
	height: 36px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.most-popular.blank {
	background-color: transparent;
}

.monthly-cost-container-content .monthly-cost-container {
	border-radius: 5px;
	border:1px solid #E8E9E9
}

.monthly-cost-container .monthly-header {
	border-radius: 5px 5px 0px 0px;
}

.plan-comparison, .create-your-own {
	padding-top: 80px;
	padding-bottom: 80px;
}

.create-your-own {
	margin-bottom: 300px;
	background-color: #F7F8FA;
}

.plan-comparison {
	padding-top: 80px;
	padding-bottom: 180px;
	background-image: linear-gradient(#F7F8FA, #F7F8FA00);
}

.plan-comparison .pricing-plan .plan-t {
	text-align: left;
	color: #7266FB;
	font-family: 'Poppins', sans-serif;
	font-weight: bold;
	font-size: 18px;
}

.plan-comparison .pricing-plan .plan-label-x {
	color: #4D4D4D;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 30px;
    text-align: left;
    line-height: 40px;
    margin-top: 10px;
}

.privacy-plan-tabs .modal-pricing-table {
	height: auto;
}

.privacy-plan-tabs .modal-pricing-table .monthly-l {
	color: #4D4D4D;
	font-family: 'Roboto', sans-serif;
	font-size: 14px;
	font-weight: 500;
	text-transform: uppercase;
} 

.privacy-plan-tabs .modal-pricing-table .billed-a {
	color: #7266FB;
	font-family: 'Roboto', sans-serif;
	font-size: 14px;
	font-weight: 500;
	text-transform: uppercase;
}

.deenfund-tooltip-p {
	position: relative !important;
}


.modal-pricing-table.table_container .most-left img{
	position: unset;
}


.create-your-own-main-td:hover {
	background-color: #fff !important;
}

.cut-price {
	color: #D3D8DA;
	font-size: 26px;
	font-family: 'Poppins', sans-serif;
	font-weight: 500;
	margin-right: 5px;
}

.privacy-plan-tabs .modal-label img {
	position: relative;
	top: 5px;
}

.privacy-plan-tabs .modal-pricing-table .main-head-row {
	background-color: #f7f8fa;
	border: 1px solid #E4E8EC;
	text-transform: uppercase;
}

.main-head-row .text-t {
	color: #4D4D4D;
	font-family: 'Poppins', sans-serif;
	font-weight: bold;
	font-size: 14px;
}

.main-head-row .light-text-t {
	color: #818E94;
	font-family: 'Poppins', sans-serif;
	font-size: 12px;
	text-transform: initial;
}

.main-head-row img {
	position: relative;
	top: 5px;
}

.dark-coming {
	color: #818E94 !important;
	font-family: 'Poppins', sans-serif !important;
	font-size: 14px !important;
	font-weight: 500 !important;
	text-transform: capitalize;
}

.monthly-l.font-medium, .billed-a.font-medium {
	font-weight: 500 !important;
}

.privacy-plan-tabs .modal-pricing-table .more-integ {
	color: #818E94 !important;
	font-family: 'Roboto', sans-serif !important;
	font-size: 14px !important;
	text-align: center !important;
	font-weight: 500;
}

.choose_title_section{
	padding-bottom: 30px !important;
}

.create-your-own .custom-plan .custom-t {
	color: #4D4D4D;
	font-family: 'Poppins', sans-serif;
	font-weight: bold;
	font-size: 30px;
	text-align: center;
}

.create-your-own .custom-plan .custom-label {
	color: #818E94;
	font-family: 'Poppins', sans-serif;
	font-size: 18px;
	text-align: center;
	margin-top: 16px;
}

.create-your-own .deenfund-section {
	background: #fff;
    padding: 20px;
    border-radius: 4px;
    margin-bottom: 20px;
}

.create-your-own .deenfund-section .accept-t {
	color: #818E94;
	font-family: 'Poppins', sans-serif;
	font-size: 16px;
	margin-top: 10px;
	margin-bottom: 15px;
}

.create-your-own .deenfund-section .custom-q {
	text-align: center;
	border-radius: 5px;
}

.check-plan-container {
	border: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.check-plan-container:hover {
	cursor: pointer;
    box-shadow: 0px 1px 1px -1px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 10px 0px rgba(0,0,0,0.12) !important;
}
.create-your-own .deenfund-section .custom-info {
	text-align: center;
	color: #4D4D4D;
	font-family: 'Poppins', sans-serif;
	font-size: 16px;
	font-weight: 500;
}

.create-your-own .deenfund-section .custom-p-price {
	text-align: center;
	color: #4D4D4D;
	font-family: 'Poppins', sans-serif;
	font-size: 20px;
	font-weight: 500;
}

.create-your-own .see-feature-btn {
	color: #7266FB;
	font-family: 'Poppins', sans-serif;
	font-weight: 700;
	margin-top: 20px;
}

.deenfund-section .dark-blue-circle {
	background-color: #7266FB;
	width: 40px;
	height: 40px;
	border-radius: 40px;
	display: inline-block;
}

.light-blue-circle {
	background-color: #2490F9;
	width: 40px;
	height: 40px;
	border-radius: 40px;
	display: inline-block;
}

.deenfund-section .deenkiosk-t {
	color: #4D4D4D;
	font-family: 'Poppins', sans-serif;
	font-size: 29px;
	padding-left: 10px;
	line-height: 1;
	position: relative;
	top: 15px;
}
.deenfund-section .logo_img {
    max-height: 200px;
    object-fit: contain;
    width: auto !important;
    height: 40px;
}
.create-your-own .feature-details {
	margin-top: 20px;
}

.create-your-own .feature-title {
	display: inline-block;
	padding-left: 15px;
    position: relative;
    bottom: 8px;
}

.create-your-own .feature-title p {
	color: #4D4D4D;
	font-family: 'Poppins', sans-serif;
	font-size: 14px;
	font-weight: 500;
}

.create-your-own .feature-title .see-feature-btn {
	margin-top: 5px;
}

.no-of-devices .MuiSelect-outlined.MuiSelect-outlined {
    padding-right: 32px;
    font-weight: 500;
    color: #4D4D4D;
}

.create-your-own .no-of-devices {
	display: inline;
    float: right;
	width: 200px;
	font-family: 'Poppins', sans-serif;
}

.create-your-own .no-of-devices .MuiFormControl-root {
	width: 100%;
}

.create-your-own .no-of-devices .MuiOutlinedInput-root {
	top: 10px;
	height: 46px;
}

.create-your-own .custom-q.check-plan {
	background-color: #f7f6ff;
	border:1px solid #7266FB;
}

.create-your-own .check-plan-container {
	border:none;
	position: relative;
}

.create-your-own .check-plan-container .custom-q.check-plan .check-mark-circle {
	position: absolute;
	top: 8px;
	right: 8px;
}

.integration-section-custom .title-a {
	color: #4D4D4D;
	font-family: 'Poppins', sans-serif;
	font-size: 30px;
	font-weight: bold;
}

.integration-section-custom .accept-t {
	color: #818E94;
	font-family: 'Poppins', sans-serif;
	font-size: 16px;
	margin:10px 0;
	margin-bottom: 20px;
}

.integration-header {
	background-color: #f1f3f6;
	color: #4D4D4D;
	padding: 14px;
	
}

.integration-header .header-xt {
	font-weight: bold;
	color: #4d4d4d;
	font-size: 14px;
	
}

.integration-header .first_td{
	padding-left: 39px;
}

.event-dfx {
	display: flex;
	justify-content: space-between;
	text-transform: capitalize;
}

.view-label {
	color: #818E94;
	font-size: 14px !important;
	margin-top: 12px !important;
}

.view-label .see-feature-btn {
	margin-top: 0;
}

.monthly-cost-container .monthly-header {
	text-align: center;
	background-color: #fff;
	padding-top: 30px;
	padding-bottom: 10px;
}

.monthly-cost-container .monthly-header .monthly-t {
	color: #4D4D4D;
	font-family: 'Poppins', sans-serif;
	font-weight: bold;
	font-size: 18px;
}

.monthly-cost-container .monthly-header .monthly-p {
	color: #4D4D4D;
	font-family: 'Poppins', sans-serif;
	font-weight: 500;
	font-size: 26px;
}

.monthly-cost-container .monthly-header .monthly-l-price {
	color: #818E94;
	font-size: 14px;
}

.monthly-cost-container .monthly-header .purchase-btn {
	background-color: #7266FB;
    color: #fff;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    border-radius: 35px;
    padding: 8px 20px;
    border: none;
    margin-top: 15px;
    font-weight: 600;
	width: 85%;
	margin-bottom: 20px;
	margin-top: 30px !important;
}

.monthly-cost-container .monthly-header hr {
	margin: 10px 0 0;
}

.monthly-cost-container .monthly-body {
	padding: 0px !important;
	background-color: #fff;
	border-radius: 0px 0px 5px 5px;
}

.monthly-cost-container .monthly-body .month-options {
	background-color: #F7F8FA;
	padding: 16px 20px;
	margin-bottom: 8px;
	position: relative;
}

.monthly-cost-container .monthly-body .month-options .m-option-t {
	color: #4D4D4D;
	font-family: 'Poppins', sans-serif;
	font-size: 16px;
	font-weight: bold;
	text-transform: capitalize;
}

.monthly-cost-container .monthly-body .month-options .m-option-l {
	color: #818E94;
	font-family: 'Poppins', sans-serif;
	font-size: 14px;
}

.close_icon {
    position: absolute;
    right: 10px;
    top: 10px;
	color: #818E94;
	cursor: pointer;
	font-size: 24px;
}

.close_icon:hover {
	color: #7266FB;
}

.close_icon.disable {
	color: #e8e9e9;
}


.event-dfx img {
	width: 24px;
	height: 24px;
	
}
.MuiButton-label{
	letter-spacing: 1.26px;
}

.table_container{
	margin-top: 30px;
	border: none !important;
}

.collapse-text {
    font-size: 12px !important;
	line-height: 24px !important;
	font-family: 'Poppins', sans-serif !important;
	font-weight: 400;
	color: #828282;
}


.package_card:hover{
	box-shadow: 0px 1px 1px -1px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 10px 0px rgba(0,0,0,0.12) !important;
}


.integrations_table tr td{
	text-align: left;
}

.integrations_table tr td p{
	font-size: 14px;
	width: auto !important;
}


.table_txt{
	display: flex;
	align-items: center;
}

.integrations_table tr td .header-xt{
	font-family: 'Poppins', sans-serif !important;
}

.integrations_table tr td .event-dfx {
    width: 100%;
    padding-left: 0px !important;
    font-weight: 500;
    position: relative;
	top: 1px;
	font-family: 'Poppins', sans-serif !important;
	font-size: 14px;
	color: #4D4D4D;
}

.modal-pricing-table tr:hover {
    background:#F7F8FA;
    cursor: pointer;
}

.MuiTableCell-root{
	padding: 14px 30px !important
}

.integrations_table tr td .PrivateSwitchBase-root-1 {
    padding: 0;
}

.label_device{
	font-size: 14px !important;
	font-family: 'Poppins', sans-serif !important;
	color: #818E94 !important;
}

.devider {
    margin-top: 15px !important;
    margin-bottom: 20px !important;
}

.MuiSwitch-colorSecondary.Mui-checked {
    color: #7266FB !important;
}

.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
	background-color: #7266FB !important;
	opacity: 0.4;
}

.packges_container {
	padding: 14px 20px !important;
}

.prod_img{
	width: 90px;
	height: 90px;
}

.cuIYFB {
	background-color: #7266fb !important;
}
  
.jDNNKh.bg-success {
	background-color: #7266FB !important;
}
  
.bg-success {
	background-color: #7266FB !important;
}
  
.hd_amount{
	display: block;
}



.see-detail-btn {
	margin: 10px 0;
}
.see-detail-btn span {
	color: #7266FB !important;
	font-family: 'Poppins', sans-serif !important;
	font-size: 14px !important;
}

.select-btn {
    background-color: #7266FB !important;
    color: #fff !important;
    font-family: Poppins, sans-serif !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    width: 206px;
    border-radius: 25px !important;
    margin: 10px 0 !important;
	height: 46px;
	margin-top: 16px !important;
}

.total-cost {
	font-family: 'Poppins', sans-serif;
	font-size: 14px !important;
	color: #818E94 !important;
}
.monthly-left {
	font-size: 14px !important;
	text-transform: capitalize;
}
.dark-blue-circle {
    background-color: #7266FB;
    width: 26px;
    height: 26px;
    border-radius: 40px;
    display: inline-block;
}
.deenkiosk-t {
    color: #4D4D4D;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    padding-left: 10px;
    line-height: 1;
    position: relative;
	top: 4px;
	font-weight: 500;
}
.see-feature-top-bar {
	margin: 0 !important;
	padding-top: 18px;
}

.MuiTooltip-popper .MuiTooltip-tooltip {
	background-color: #4D4D4D;
	text-align: center;
	font-size: 12px;
	color: #fff;
}

.MuiTooltip-popper .MuiTooltip-arrow {
	color: #4d4d4d;
}

.tooltip-btn-container img {
	position: unset !important;
}

.info-img-tooltip-css {
	position: absolute;
	right: 0;
}

.hardware-details-number-of-devices-input-label .MuiInputLabel-outlined {
	font-size: 12px;
}

.integrations-modal-t .modal-pricing-table.integrations_table {
	position: relative;

}

.modal-pricing-table.integrations_table {
	border:none;
}

.modal-pricing-table.integrations_table .close_icon {
	position: absolute;
    right: 30px;
    top: 30px;
    color: #818E94;
    cursor: pointer;
    font-size: 24px;
}

.modal-pricing-table.integrations_table tr td:first-child {
	min-width: 200px;
}

.modal-pricing-table.integrations_table tr td:nth-child(2) {
	min-width: 200px;
}

.integrations-modal-t .modal-pricing-table.integrations_table tr td:first-child {
	min-width: 20px;
}

.custom-price-modal {
	width: 100%;
	text-transform: capitalize !important;
	padding: 0px !important;
}

.custom-price-modal .package_card{
	width: 100%;
}

.custom-price-modal:hover span {
	box-shadow: 0px 1px 1px -1px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 10px 0px rgba(0,0,0,0.12) !important;
}

.custom-price-modal .custom-p-price {
	text-transform: lowercase !important;
}

.logo_tagline{
	color: #818E94;
	font-size: 12px !important;
	margin-top: 6px !important;
	padding-bottom: 18px;
}

.MuiTable-root .MuiTableBody-root .billed_monthly{
	vertical-align: top !important;
	padding-top: 20px !important;
	width: 300px  !important;
}

.hardware-details-number-of-devices-input-label .MuiOutlinedInput-input {
	height: 46px !important;
	width: 100% !important;
	padding: 0px 10px !important;
}

.number_select {
	margin-top: 10px !important;
}

.integrations-modal-t .all-integration-features-modal .tooltip-btn-container, .integration-type-feature .tooltip-btn-container {
    min-width: 24px!important;
    position: relative !important;
    right: 0;
    top: initial !important;
    bottom: initial !important;
    float: right;
    padding: 0 !important;
    margin-left: 15px;
}

 .pricing-plan .tooltip-btn-container {
    min-width: 24px!important;
    position: relative !important;
    right: 0;
    top: initial !important;
    bottom: initial !important;
    float: right;
    padding: 0 !important;
    margin-left: 15px;
}

.plan-comparison .dark-coming img{
	position: relative;
	top: 4px;
}


.integrations_table .table_txt .integration_table_text{
	display: flex;
	align-items: center;
}

.integrations_table .table_txt .tooltip-btn-container {
    min-width: 24px!important;
    position: relative !important;
    right: 0;
    top: initial !important;
    bottom: initial !important;
    float: right;
    padding: 0 !important;
    margin-left: 15px;
}


.pricing-planTable .MuiTableBody-root .MuiTableRow-root .MuiTableCell-body {
    padding: 12px 25px !important;
    padding-right: 20px !important;
}

.pricing-modal .MuiTableCell-root {
    padding: 12px 22px!important;
}

.flexCol{
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.flexCol .tooltip-btn-container {
    position: relative !important;
    right: 0 !important;
    bottom: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
    top: 0 !important;
}

.MuiTableBody-root .integration-header .MuiTableCell-body{
	padding: 14px 30px !important;
}


.faq-section .MuiCollapse-container {
    width: 100%;
    margin-left:0%;
    position: relative;
    top: 0px;
    background-color: transparent;
}

.img{
	height: auto !important;
}

.tableMonthlySwitch {
    display: flex;
    align-items: baseline;
}

.modal-pricing-table .MuiTable-root .MuiTableBody-root{
	height: auto !important;
}

@media (max-width: 767px) {
	.create-your-own .no-of-devices {
		float: none;
	}

	.pricing-modal .modal-pricing-table tbody {
		width: 1000px;
	}


	.plan-card .pricing-body {
		padding: 20px 15px;
		min-height: initial;
	}

	.model_backBtn {
		position: relative;
	}

	.hardware-top-section button {
		left: 20px;
		font-size: 11px;
		margin-bottom: 13px;
	}
}


@media (max-width: 576px) {
	.pricing-modal {
		width: 95%;
	}

	.deenkiosk-t {
		font-size: 22px;
		top: 5px;
	}

	.dark-blue-circle {
		width: 30px;
		height: 30px;
	}

	.pricing-modal .close_icon {
		top: 13px !important;
	}

	.create-your-own .custom-plan .custom-t, .integration-section-custom .title-a {
		font-size: 24px;
	}
}


