.topBar {
	color: #818E94;
	font-size: 14px !important;
	font-family: 'Poppins', sans-serif !important;
	font-weight: 400;
	text-align: right;
	padding: 12px 0;
}

.topBar a {
	color: #7266FB;
	font-weight: 600;
	text-transform: uppercase;
	text-decoration: none !important;
}

.mob-nav {
	background: #7266fb;
	padding: 0px;
	transition: all 1.5s ease;
	transition-timing-function: linear;
}

.mob-nav ul {
	list-style: none;
	padding: 0;
	margin:0;
	box-shadow: 0 3px 13px -4px #4E4E4D;
}
.mob-nav ul li {
	padding: 15px  0;
	border-bottom: 1px solid #ccc;
	text-align: left;
}

.mob-nav ul li:last-child {
	border-bottom:none;
}

.mob-nav ul li a {
	color: #fff;
	font-size: 14px;
	padding: 0 15px;
	font-weight: 500;	
	font-family: 'Poppins', sans-serif !important;
	text-decoration: none;
}

.mob-nav ul li a:hover {
	color: #fff;
}


.navSection {
	padding: 20px 0;
	font-family: 'Poppins', sans-serif !important;
	display: flex;
    justify-content: space-between;
}

.navSection div {
    display: flex;
    justify-content: space-between;
}
.navSection a.active-link {
	color: #7266FB;
}

.navSection a {
	color: #4D4D4D;
	font-size: 14px;
	padding: 0 15px;
	font-weight: 500;
	font-family: 'Poppins', sans-serif !important;
}

.get-start-btn {
    background-color: #7266FB !important;
    float: right;

}

.button span{
	letter-spacing: 1.26px;
	color: #fff !important;
    font-size: 14px !important;
    font-weight: 500 !important;
	text-transform: uppercase;
	opacity: 1;
	position: relative;
	font-family: 'Poppins', sans-serif !important;
	line-height: 2;
}

.button {
	display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    height: 46px;
	padding: 0px 20px !important;
	border-radius: 100px !important;
}

.button  span svg {
    font-size: 15px;
    margin-left: 4px;
}

.logo-nav {
	float: right;
}

.logo-nav p {
	display: inline-block;
	margin-right: 15px;
    padding-top: 8px;
}

.logo a{
	padding: 0;
}

.logo img{
	width: 190px;
}

a, a:hover {
	text-decoration: none;
}

.Footer {
	background: #fff;
}

.footer-text {
	line-height: 30px !important;
	color: #818E94 !important;
	font-family: 'Roboto', sans-serif !important;
	line-height: 28px !important;
	font-size: 16px !important;
	color: #818E94 !important;
	padding-right: 60px !important;
}

.footer-heading {
	color: #4D4D4D !important;
	font-family: 'Poppins', sans-serif !important;
	font-weight: bold !important;
}

.footer-l {
	margin-top: 25px;
}

.footer-l a:first-child {
	padding-top: 0;
}

.footer-l a {
	display: block !important;
	color: #818E94 !important;
	font-size: 16px !important;
	padding: 8px 0;
	font-family: 'Roboto', sans-serif;
}

.footerContainer {
	padding: 60px 0;
}

.inner-footer-Container {
	background: #F8F8F8;
	font-size: 12px;
	padding: 5px 0;
}

.inner-footer-Container .innerContainer-content {
	display: flex;
	justify-content: space-between;
}

.inner-footer-Container .footer-text a, .inner-footer-Container .footer-text {
	font-size: 12px !important;
	color: #828282 !important;
}

.bottom-footer {
	padding: 10px 0;
}

.bottom-footer .innerContainer-content {
	display: flex;
	justify-content: space-between;
	font-size: 12px !important;
	color: #828282 !important;
}

.bottom-footer .innerContainer-content p {
	font-size: 12px !important; 
	color: #828282 !important;
	display: flex;
	align-items: center;
	padding-right: 0 !important;
}


.bottom-footer .innerContainer-content p img {
	width:146px;
}

.container {
	font-family: 'Poppins', sans-serif !important;
}

.MuiAppBar-colorPrimary {
	background-color: #fff !important;
	display: flex !important;
    justify-content: space-between !important;
    box-shadow: none !important;
}

.menu-icon {
	margin-left: auto !important;
}

.header_btn {
    height: 40px !important;
    width: 220px;
    max-width: initial;
    padding: 10px 20px !important;
}

@media (min-width: 1280px) {
	.MuiContainer-maxWidthLg {
    	max-width: 1240px !important;
	}
}


@media (max-width: 1000px) {
	.navSection a {
		padding: 0 8px;

	}

	.logo-nav p {
		display: inline-block;
		margin-right: 0;
		padding-top: 8px;
	}
}

@media (min-width: 911px) {
	header.MuiPaper-root, .mobile-navigation {
		display: none !important;
	}
}

@media (max-width: 910px) {
	.navSection .logo-nav, .navSection, .topBar {
		display: none;
	}

	.how-it-work-section {
		background-color: #5449d4;
	}
}

@media (max-width: 600px) {
	.inner-footer-Container .innerContainer-content {
		justify-content: center !important;
	}
}

@media (max-width: 576px) {
	.inner-footer-Container .footer-text a, .inner-footer-Container .footer-text {
		padding-right: 0 !important;
	}
}